import React, { useState } from 'react';

import apiCurrency from '../../../service/apiCurrency';

import Input from '../../../components/Input';
import Button from '../../../components/Button';

const EditCurrency = ({close, update, item}) => {

  const [ nameValue, setName ]                = useState(item.currency);
  const [ currencyShort, setCurrencyShort]    = useState(item.currency_short);
  const [ idCurrency, setIdCurrency ]         = useState(item.id);
  const [ signCurrency, setSignCurrency ]     = useState(item.sign);
  const [ course, setCourse ]                 = useState(item.course);

  const handleSubmit = e => e.preventDefault();
  const handleName = (value) => setName(value);
  const handleIdCurrency = (value) => setIdCurrency(value);
  const handleSignCurrency = (value) => setSignCurrency(value);
  const handleCurrencyShort = (value) => setCurrencyShort(value);
  const gandleCourse = (value) => setCourse(value);

  const updateItem = () => {
    const api = new apiCurrency();
    api.editCurrency(nameValue, idCurrency , currencyShort, signCurrency, course).then(res => update(res));
  };

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit} className="form form-add shadow-box">
        <h2>Изменить валюту в список валют</h2>
        <div className="row align-content-center">

          <div className="col-2">
            <Input
              defaultValue={item.currency}
              value={nameValue} placeholder={"Введите название"}
              changeValue={handleName} />
          </div>

          <div className="col-2">
            <Input
            defaultValue={item.id}
            value={idCurrency} type="number"
            placeholder={"Введите id"}
            changeValue={handleIdCurrency} />
          </div>

          <div className="col-2">
            <Input
              defaultValue={item.currency_short}
              value={currencyShort}
              placeholder={"Введите краткое название"}
              changeValue={handleCurrencyShort} />
          </div>

          <div className="col-2">
            <Input
              defaultValue={item.sign}
              value={signCurrency}
              placeholder={"Введите знак валюты"}
              changeValue={handleSignCurrency} />
          </div>

          <div className="col-2">
            <Input
              defaultValue={item.course}
              type="number"
              value={course}
              placeholder={"Введите курс"}
              changeValue={gandleCourse} />
          </div>

          <div className="col-2">
            <Button mode="small" text="Добавить" onClick={() => {updateItem();close()}}/>
          </div>
        </div>
      </form>
    </React.Fragment>
  );
};
export default EditCurrency;