import React from 'react';
import { Link } from "react-router-dom";

import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import './Table.sass';

const Table = ({itemList, deleteItem, sizeShow, weightShow, optShow, megaoptShow, rozShow, storageShow, productsShow, activeLang}) => {

  const prooductItems = itemList.map((item, i) => {
    return (
      <div className="row" key={item.id}>

        <div className="table-item d-flex w-100" style={ i % 2 ? {"background" : "#9e9e9e1a"} : {"background" : "#fff"}}>
          <div className="table-item-article col-2 justify-content-center">
            <Link key={item.id} to={`/products/${item.id}`}>
              <div>{item.sku ? item.sku : '----'}</div>
            </Link>
          </div>

          <div className="table-item-name col-2">
            <Link key={item.id} to={`/products/${item.id}`}>
              {item.langList ? item.langList[activeLang.id - 1].product_title : '----'}
            </Link>
          </div>

          {/* начало новых полей */}

          <div className="table-item-price col-1 d-flex justify-content-center  align-items-center">
            {sizeShow ?
            <Link key={item.id} to={`/products/${item.id}`}>
              {item.size ? item.size : '----'}
            </Link> : null}
          </div>

          <div className="table-item-price col-1 d-flex justify-content-center align-items-center">
            {weightShow?
              <Link key={item.id} to={`/products/${item.id}`}>
                {item.weight ? item.weight : '----'}
              </Link> : null
            }
          </div>

          <div style={{"fontWeight": "bold"}} className="table-item-price col-1 d-flex justify-content-center align-items-center">
            {optShow ?
              <Link key={item.id} to={`/products/${item.id}`}>
                {item.regionData[0] ? item.regionData[0].prices[5].price : '----'}
              </Link> :null
            }
          </div>

          <div style={{"fontWeight": "bold"}} className="table-item-price col-1 d-flex justify-content-center align-items-center">
            {megaoptShow ?
              <Link key={item.id} to={`/products/${item.id}`}>
                { item.regionData[0] ? item.regionData[0].prices[4].price : '----'}
              </Link> : null
            }
          </div>

          <div  style={{"fontWeight": "bold"}} className="table-item-price col-1 d-flex justify-content-center align-items-center">
            {rozShow ?
              <Link key={item.id} to={`/products/${item.id}`}>
                { item.regionData[0] ? item.regionData[0].prices[6].price : '----'}
              </Link> : null
            }
          </div>

          <div className="table-item-price col-1 d-flex justify-content-center align-items-center">
            {storageShow ?
              <Link key={item.id} to={`/products/${item.id}`}>
                { item.regionData[0] ? item.regionData[0].prices[3].price : '----'}
              </Link> : null
            }
          </div>

          <div className="table-item-price col-1 d-flex justify-content-center align-items-center">
            {productsShow?
              <Link key={item.id} to={`/products/${item.id}`}>
                { item.regionData[0] ? item.regionData[0].prices[0].price : '----'}
              </Link> : null
            }
          </div>

          {/* конец новых полей */}

          <div onClick={() => deleteItem(item.id)}
            className="col-1 expenses-item text-center d-flex justify-content-center">
            <div  className="text-center">
              <DeleteForeverIcon />
            </div>
          </div>
        </div>

      </div>
    );
  });
  return (
    <div className="table">
      {/* <div className="table-content-border"></div> */}
      {prooductItems}
    </div>
  );
};
export default Table;