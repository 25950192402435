import React, { useEffect, useState } from 'react';

import apiProductType from '../../../service/apiProductType';
import TableHeader from '../TableHeader';
import ModalWindow from '../../../components/ModalWindow';
import Button from '../../../components/Button';

import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import './Table.sass';
import AddProductType from '../AddProductType';

const Table = () => {
  const [ productTypes, setProductTypes ] = useState([]);
  const [ activeAdd, setActiveAdd ] = useState(false);

  useEffect(() => {
    const api = new apiProductType();
    api.getProductType().then(res => setProductTypes(res));
  }, []);

  const updateAdd = (list) => setProductTypes(list);
  const deleteItem = (id) => {
    const api = new apiProductType();
    api.deleteProductType(id).then(res => setProductTypes(res))
  };

  const closeAdd = () => setActiveAdd(false);

  const expListItem = productTypes.map(item => {
    return (
      <div className="regions langs row" key={item.id}>
        <div className="col-10 regions-item">
          <div className="ml-4">
            {item.title}
          </div>
        </div>
        <div
          onClick={() => deleteItem(item.id)}
          className="col-2 regions-item text-center d-flex justify-content-center">
          <div className="text-center">
            <DeleteForeverIcon />
          </div>
        </div>
      </div>
    );
  });

  return (
    <div className="table d-flex flex-column">
      {activeAdd ?
        <ModalWindow close={closeAdd}>
          <AddProductType
            update={updateAdd}
            close={closeAdd}/>
        </ModalWindow> : null}

      <div className="ml-auto mb-4">
        <Button text="Добавить" onClick={() => setActiveAdd(true)} />
      </div>

      <div className="table-content shadow-box">
        <TableHeader />
        <div className="table-content-border"></div>
        {expListItem}
      </div>
    </div>
  );
};

export default Table;
