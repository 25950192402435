import React from 'react';

import Table from '../Table';
import './Production.sass';

const Production = () => {
  return(
    <div className='inner'>
      <Table typeExp={1}/>
    </div>
  );
};

export default Production;