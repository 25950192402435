import React, { useEffect, useState } from 'react';

import ApiProducts from '../../../../../service/apiProducts';
import PriceProductExp from '../PriceProductExp';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import AddExp from '../AddExp';
import './TransportExp.sass';

const TransportExp = ({ productId, idRegion, region, product }) => {

  const [ expList, setExpList ] = useState([]);
  const [ sum, setSum ]         = useState(null);

  useEffect(() => {
    const api = new ApiProducts();
    const controller = new AbortController();
    const signal = controller.signal;

    api.getExpTransportItem(productId, idRegion, signal).then(res => {setExpList(res.logisticExpenses); setSum(res.sumLogisticAll)});
    return () => {
      controller.abort();
    };
  }, [ productId, idRegion ]);

  const deleteExp = (idExp) => {
    const api = new ApiProducts();
    api.deleteExp(productId, idExp, idRegion, 2).then(data => updateExpList(data));
  };

  const updateExpList = (data) => {
    setExpList(data.logisticExpenses);
    setSum(data.sumLogisticAll);
  };

  const addExpItem = (data) => setExpList(data.logisticExpenses);
  // content-fixed
  return (
    <div className="form form-add shadow-box">
      <div className="d-flex justify-content-between align-items-center">
        <h2>Транспортные расходы - {region.title}</h2>
        <h2>{product.sku}</h2>
        <h2>{product.title}</h2>
      </div>
      <AddExp idRegion={idRegion} update={addExpItem} typeExp={2} productId={productId} />
      <div className="form-product-table">
        <div className="row">
          <div className="col-1">
            <h2 className="d-flex justify-content-center align-items-center">Номер</h2>
          </div>
          <div className="d-flex col-3">
            <h2 className="d-flex align-items-center">Наименование</h2>
          </div>

          <div className="d-flex col-4">
            <h2 className="d-flex align-items-center">Комментарии</h2>
          </div>

          <div className="d-flex justify-content-center col-2">
            <h2 className="d-flex justify-content-center align-items-center">Цена</h2>
          </div>

          <div className="d-flex justify-content-center col-2">
            <h2 className="d-flex justify-content-center align-items-center">Удалить</h2>
          </div>

        </div>
        <div className="row">

          {
            expList.map(item => {
              return (
                <React.Fragment  key={item.id}>
                  <div className="col-1">
                    <div className="form-product-table-number justify-content-center d-flex align-items-center">
                      {item.number}
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="form-product-table-number d-flex align-items-center">
                      {item.title}
                    </div>
                  </div>

                  <div className="col-4 d-flex align-items-center">
                    <div className="form-product-table-number d-flex align-items-center tooltip">
                      <div className="description">{item.description? item.description : "----"}</div>
                      {item.description ? <div className="tooltiptext">{item.description}</div> : null}
                    </div>
                  </div>

                  <div className="col-2  d-flex justify-content-center align-items-center">
                    <PriceProductExp type={2} idRegion={idRegion} updateExpList={updateExpList} productId={productId} item={item} />
                  </div>

                  <div className="col-2  d-flex justify-content-center align-items-center">
                    <div className="text-center form-product-table-input"
                      onClick={() => deleteExp(item.id)}>
                      <DeleteForeverIcon />
                    </div>
                  </div>
                </React.Fragment>
              )
            })
          }

        </div>

        <div className="table-content-border"></div>
        <div className="row sum-row">
          <h3 className="col-10 d-flex align-items-center">Сумма :</h3>
          <h3 className="col-2 d-flex align-items-center justify-content-center">{sum} { expList.length ? expList[0].sign: null }</h3>
        </div>
      </div>
    </div>
  );
};

export default TransportExp;