import React, { useState, useEffect } from 'react';

import apiRegions from '../../../service/apiRegions';
import apiCurrency from '../../../service/apiCurrency';
import apiLangs from '../../../service/apiLangs';

import Input from '../../../components/Input';
import Button from '../../../components/Button';
import Select from '../../../components/Select';

const AddRegion = ({close, update}) => {

  const [ nameValue, setName ]              = useState('');
  const [ descriptionValue, setDescription] = useState('');
  const [ currency, setCurrency ]           = useState([]);
  const [ langs, setLangs ]           = useState([]);
  const [ activeItemCurrency, setActiveItemCurrency ]   = useState({title: 'Выберите валюту'});
  const [ activeItemLang, setActiveItemLang ]   = useState({title: 'Выберите язык'});

  const handleSubmit = e => e.preventDefault();
  const handleName = (value) => setName(value);
  const handleDescription = (value) => setDescription(value);

  const addItem = () => {
    const api = new apiRegions();
    api.addRegion(nameValue, activeItemCurrency.id, descriptionValue, activeItemLang.id).then(res => {
      update(res);
    });
  };

  useEffect(() => {
    const api = new apiLangs();
    api.getLangs().then(langs => setLangs(langs));
  }, []);

  useEffect(() => {
    const api = new apiCurrency();
    api.getCurrency().then(res => setCurrency(res));
  }, []);

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit} className="form form-add shadow-box">
        <h2>Добавить новый регион в список регионов.</h2>
        <div className="row align-content-center">
          <div className="col-2">
            <Input value={nameValue} placeholder={"Введите название"} changeValue={handleName} />
          </div>
          <div className="col-2">
            <Select label='Выберите валюту' onClick={(item) => setActiveItemCurrency(item)} itemsList={currency} activeItem={activeItemCurrency} />
          </div>
          <div className="col-2">
            <Select label='Выберите язык' onClick={(item) => setActiveItemLang(item)} itemsList={langs} activeItem={activeItemLang} />
          </div>
          <div className="col-3">
            <Input
              value={descriptionValue}
              placeholder={"Введите комментарий"}
              changeValue={handleDescription} />
          </div>

          <div className="col-3">
            <Button mode="small" text="Добавить" onClick={() => {addItem();close()}}/>
          </div>
        </div>
      </form>
    </React.Fragment>
  );
};
export default AddRegion;