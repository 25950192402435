import React, { useState, useEffect } from 'react';


import apiProducts from '../../../../service/apiProducts';
import apiCategories from '../../../../service/apiCategories';

import Input from '../../../../components/Input';
import ModalWindow from '../../../../components/ModalWindow';
import Button from '../../../../components/Button';
import Select from '../../../../components/Select';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import SelectProduct from '../../../../components/SelectProduct';

import './EditProduct.sass';

const ListProvision = ({item, deleteItem, addItem,}) => {

  const [ count, setCount ] = useState(1);

  const handleCount = (e) => {
    if (e.target.value < 1) {
      const provision = {id: item.id, count: e.target.value}
      addItem(provision);
      setCount(1);
    } else {
      setCount(e.target.value);
      const provision = {id: item.id, count: e.target.value}
      addItem(provision);
    };
  };

  useEffect(() => {
    const provision = {id: item.id, count: 1}
    addItem(provision);
  }, []);

  useEffect(() => {
    setCount(item.amount === null ? 1 : item.amount)
  }, [ item ]);

  return (
    <div className="row mb-2">
      <div className="col-3">
        {item.sku}
      </div>
      <div className="col-3">
        {item.langList[0].product_title}
      </div>

      <div className="col-3">
        <input type="number" value={count} onChange={handleCount} />
      </div>

      <div className="col-3 justify-content-center" onClick={() => deleteItem(item.id)}>
        <DeleteForeverIcon />
      </div>
    </div>
  );

};

const EditProduct = ({ typeProduct, item, updateProduct, active, setEditActive, langList, types}) => {

  const [ nameValue, setName ]                = useState('');
  const [ nameValue1, setName1 ]              = useState('');
  const [ nameValue2, setName2 ]              = useState('');
  const [ wightValue, setWight ]              = useState(item.weight);
  const [ skuValue, setSku ]                  = useState(item.sku);
  const [ height, setHeight ]                 = useState(2);
  const [ deep, setDeep ]                     = useState(item.deep);
  const [ width, setWidth ]                   = useState(item.width);

  const [ provision, setProvision ]                       = useState([]);
  const [ categoryes, setCategoryes ]                     = useState([]);
  const [ activeProvision, setActiveProvision ]           = useState({title: "Выберите изделие", id: 0});
  const [ activeTypeProduct, setActiveTypeProduct ]       = useState(typeProduct ? typeProduct : {title: "Выберите тип продукта", id: 0});
  const [ activeCategory, setActiveTypeCategory ]         = useState({title: "Выберите категорию продукта", id: 0});

  const [ productProvisionList, setProductProvisionList ] = useState([]);
  const [ postProvision, setPostProvision ]               = useState([]);

  useEffect(() => {
    setProductProvisionList(item.childrenList);
  }, [item]);

  useEffect(() => {
    if(langList) {
      setName(langList[0].product_title);
      setName1(langList[1].product_title);
      setName2(langList[2].product_title);
    };
  }, [langList]);

  useEffect(() => {
    setPostProvision(item.childrenList.map(element => {
      return (
        {id: element.id, count: element.amount === null ? 1 : element.amount}
      )
    }));
  }, []);

  useEffect(() => {
    const api = new apiCategories();
    api.getCategories().then(categoryes => {
      setCategoryes(categoryes);
      if( categoryes.find(e => e.id === item.category_id )) {
        setActiveTypeCategory(categoryes.find(e => e.id === item.category_id))
      };
    });
  }, []);

  useEffect(() => {
    const api = new apiProducts();
    api.getProductList().then(products => {
      setProvision(products.filter(item => item.type_id === 5 || item.type_id === 4 ));
    })
  }, []);

  const handleSubmit  = e       => e.preventDefault();
  const handleName    = (value) => setName(value);
  const handleName1   = (value) => setName1(value);
  const handleName2   = (value) => setName2(value);
  const handleWight   = (value) => setWight(value);
  const handleSku     = (value) => setSku(value);
  const handleHeight  = (value) => {
    const format = String(value).replace(/(\d)(?=(\d{3})+([^\d]|$))/g, '$1 ');
    console.log(format);
    setHeight(format);
  };
  const handleDeep    = (value) => setDeep(value);
  const handleWidth   = (value) => setWidth(value);
  const close         = () => setEditActive(false);
  const editProductItem = () => {
    const api = new apiProducts();
    api.editProduct(item.id, nameValue, nameValue1, nameValue2, skuValue, wightValue, height, deep, width,
      activeTypeProduct.id,
      activeCategory.id,
      postProvision)
      .then(res => {
        updateProduct();
      });
  };

  const deleteItemProvision = (id) => {
    const arr = productProvisionList.filter(item => item.id !== id);
    const newArr = postProvision.filter( item => item.id !== id);
    setPostProvision(newArr);
    setProductProvisionList([...arr]);
  };

  const addProvision = (item) => {
    setProductProvisionList([...productProvisionList.filter(e => e.id !== item.id), item]);
  };

  return (
    <React.Fragment>
      {active ?
      <ModalWindow close={close}>
        { item.langList ?
        <form onSubmit={handleSubmit} className="form product-edit form-add shadow-box">
          <React.Fragment>
            <div className="d-flex justify-content-between">
              <h2>Изменить характеристики товара</h2>
              <div>
                <h2>Тип продукта : {typeProduct && typeProduct.title !== "Выберите тип продукта" ? typeProduct.title : 'не выбрано'} </h2>
                <h2>Категория продукта : {activeCategory && activeCategory.title !== "Выберите категорию продукта" ? activeCategory.title : 'не выбрано'}</h2>
              </div>
            </div>
            <div className="row align-content-center product-edit-item">
              <div className="col-3">
                <Input
                  defaultValue={item.langList[0].product_title}
                  value={nameValue}
                  placeholder={item.langList[0].title}
                  changeValue={handleName} />
              </div>
              <div className="col-3">
                <Input
                  defaultValue={item.langList[1].product_title}
                  value={nameValue1}
                  placeholder={item.langList[1].title}
                  changeValue={handleName1} />
              </div>
              <div className="col-3">
                <Input
                  defaultValue={item.langList[2].product_title}
                  value={nameValue2}
                  placeholder={item.langList[2].title}
                  changeValue={handleName2} />
              </div>
              <div className="col-3">
                <Input
                  defaultValue={item.sku}
                  value={skuValue}
                  placeholder={"Введите артикул"}
                  changeValue={handleSku} />
              </div>
            </div>
          </React.Fragment>

          <div className="table-content-border"></div>

          <h2 className="mt-4">Вем товара {item.weight} кг</h2>
          <div className="row align-content-center">
            <div className="col-2">
              <Input type="number" value={wightValue} defaultValue={item.weight} placeholder={"Введите вес"} changeValue={handleWight} />
            </div>
          </div>

          <h2 className="mt-4">Объём товара {item.size} м<sup>3</sup></h2>
          <div className="row mt-5">

            <div className="col-2">
              <Input type="number"
                defaultValue={item.height}
                value={height} placeholder={"Введите высоту"} changeValue={handleHeight} />
            </div>

            <div className="col-2">
              <Input defaultValue={item.deep} type="number" value={deep} placeholder={"Введите глубину"} changeValue={handleDeep} />
            </div>

            <div className="col-2">
              <Input defaultValue={item.width} type="number" value={width} placeholder={"Введите ширину"} changeValue={handleWidth} />
            </div>

          </div>

          <div className="table-content-border"></div>

          <div className="row">
            <div className="col-3">
              <Select
                label="Выберите тип продукта"
                onClick={(item) => setActiveTypeProduct(item)}
                itemsList={types}
                activeItem={activeTypeProduct} />
            </div>

            <div className="col-3">
              <Select
                label="Выберите категорию продукта"
                onClick={(item) => setActiveTypeCategory(item)}
                itemsList={categoryes}
                activeItem={activeCategory} />
            </div>
          </div>

          <div className="table-content-border"></div>
          { activeTypeProduct.id === 1 ?
            <div className="row">
              <div className="col-4">
                <SelectProduct
                  label="Добавить изделие"
                  onClick={(item) => addProvision(item)}
                  itemsList={provision}
                  activeItem={activeProvision} />
              </div>
              <div className="col-8 provision-list">
                <div className="row mb-4" style={{"fontWeight" : "bold"}}>
                  <div className="col-3">
                    Артикул
                  </div>
                  <div className="col-3">
                    Наименнование
                  </div>
                  <div className="col-3">
                    Кол-во
                  </div>
                  <div className="col-3">
                    Удалить
                  </div>
                </div>
                {productProvisionList.map(element => <ListProvision
                    deleteItem={deleteItemProvision}
                    addItem={(provision) => {
                      setPostProvision([...postProvision.filter(e => e.id !== provision.id), provision]);
                    }}
                    key={element.id} item={element} />)}
              </div>
            </div> : null}

          <div className="row mt-5">
            <div className="col-2">
              <Button text="Изменить" onClick={() => {
                close();
                editProductItem();
              }}/>
            </div>
          </div>

        </form> : null}
      </ModalWindow> : null}
    </React.Fragment>
  );
};
export default EditProduct;