import React, { useState, useEffect } from 'react';

import Input from '../Input';
import Button from '../Button';
import ApiExpenses from '../../service/apiResource';


const AddExp = ({close, update, type}) => {

  const [ nameValue, setName ]              = useState('');
  const [ numberValue, setNumber]           = useState();
  const [ descriptionValue, setDescription] = useState('');
  const [ fullExpList, setFullExpList ]     = useState([]);

  const handleSubmit          = e => e.preventDefault();
  const handleName            = (value)  => setName(value);
  const handleNumber          = (value) => setNumber(value);
  const handleDescription     = (value) => setDescription(value);

  useEffect(() => {
    const api = new ApiExpenses();
    api.getExpensesFull().then(res => {
        let arrIdExpNumber = res.map(item => item.number);
        setFullExpList(arrIdExpNumber);
      });
  }, []);

  const addItem = () => {
    const api = new ApiExpenses();
    api.addExpenses(nameValue, numberValue, type, descriptionValue).then(res => {
      const arr = res.filter(item => item.type === type);
      update(arr);
    });
  };

  const validateNumber = () => {
    const active = fullExpList.findIndex(item => item === numberValue);
    if( numberValue && nameValue && active === -1) {
      addItem();
      close();
    }
    else if ( !numberValue ) { alert("Введите номер!")}
    else if (active !== -1)
      {alert(`Номер занят!`)}
    else if (!nameValue) { alert("Введите название!") }
  };

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit} className="form form-add shadow-box">
        <h2>Добавить новый расход в список расходов.</h2>
        <div className="row align-content-center">
          <div className="col-2">
            <Input type="number"
              value={numberValue}
              placeholder={"Введите номер"}
              changeValue={handleNumber} />
          </div>

          <div className="col-3">
            <Input value={nameValue} placeholder={"Введите название"} changeValue={handleName} />
          </div>

          <div className="col-4">
            <Input
              value={descriptionValue}
              placeholder={"Введите комментарий"}
              changeValue={handleDescription} />
          </div>

          <div className="col-3">
            <Button mode="small" text="Добавить" onClick={() => {validateNumber(); }}/>
          </div>
        </div>
      </form>
    </React.Fragment>
  );
};
export default AddExp;