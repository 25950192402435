import React, {useState, useEffect, useContext} from 'react';

import {useParams} from "react-router-dom";
import { CurrencyContext } from '../../../context/currencyContext';

import ApiProducts from '../../../service/apiProducts';
import apiProductType from '../../../service/apiProductType';
import Button from '../../../components/Button';
import Table from './Table';
import EditProduct from './EditProduct';
import './Product.sass';

const Product = () => {
  const [ product, setProduct ]             = useState([]);
  const [ editActive, setEditActive ]       = useState(false);
  const [ update, setUpdate]                = useState(false);
  const [ allRegions, setAllRegions ]       = useState(false);
  const [ regionList, setRegionList ]       = useState([]);
  const [ allPrice, setAllPrice ]           = useState(false);
  const [ prices, setPrices ]               = useState([]);
  const [ deleteIdPrice, setDeleteIdPrice ] = useState(null);
  const [ idArr, setIdArr ]                 = useState([]);
  const [ typeProduct, getTypeProduct ]     = useState('');
  const [ children, setChildren ]           = useState([]);
  const [ types, setTypes ]                 = useState([]);

  const [ more, setMore ]                   = useState(false);

  const currency = useContext(CurrencyContext);
  const {currencyId} = currency;
  let { id } = useParams();

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    const api = new ApiProducts();

    api.getPrices(signal).then(res => setPrices(res));
    return () => {
      controller.abort();
    };
  }, [ allPrice ]);

  useEffect(() => {
    const api = new ApiProducts();
    const controller = new AbortController();
    const signal = controller.signal;

    api.getProduct(id, signal).then(res => {
      setProduct(res);
      setChildren(res.childrenList);
      if (regionList !== res.regionList) setRegionList(res.regionList);
    });
    return () => {
      controller.abort();
    };
  }, [id, update, currencyId, allRegions]);

  useEffect(() => {
    if (idArr.length) {
      setPrices(prices.filter(item => item.id !== deleteIdPrice));
    };
  }, [ deleteIdPrice ]);

  useEffect(() => {
    const api = new apiProductType();
    api.getProductType().then(res => {
      setTypes(res);
      const title = res.find(e => e.id === product.type_id);
      getTypeProduct(title ? title : "не выбран");
    });
  }, [product]);

  const updatePrice = () => setAllPrice(!allPrice);
  const deletePrice = (id) => setDeleteIdPrice(id);

  const updateRegion = () => setAllRegions(!allRegions);
  const deleteRegion = (id) => setRegionList(regionList.filter((item => item.id !== id)));
  const close = () => setEditActive(false);
  const updateProduct = () => setUpdate(!update);

  const addId = (id) => {
    const arr = [...idArr, id];
    arr.sort((a,b) => a - b);
    setIdArr([...arr]);
  };

  return(
    <React.Fragment>
      { editActive ?
        <EditProduct
        types={types}
        typeProduct={typeProduct}
        updateProduct={updateProduct}
        active={editActive}
        setEditActive={close}
        item={product}
        langList={product.langList} /> : null
      }
      <div className="inner product flex-column">
        <div className="product-content">
          <div className="row">
            <div className="col-2">
              <h4 className="product-article">{product.sku}</h4>
              <div  className="product-title">
                {
                  product.langList ?
                  <React.Fragment>
                    <div className="product-name">{product.langList[0].product_title}</div>
                    <div className="product-name">{product.langList[1].product_title}</div>
                    <div className="product-name">{product.langList[2].product_title}</div>
                  </React.Fragment>: null
                }
              </div>
            </div>

            <div className="col-2">
              <div className="product-details d-flex flex-column ">
                <h4>Характеристики товара</h4>
                <div className="product-details-item">
                  <span>Вес товара</span>
                  <span>{product.weight ? product.weight : "----"} кг</span>
                </div>
                <div className="product-details-item">
                  <span>Объём товара</span>
                  <span>{product.size ? product.size : "----"} м <sup>3</sup></span>
                </div>
              </div>
              <div className="product-details-btn">
                <Button mode="small" onClick={() => setEditActive(true)} text="Редактировать" />
              </div>
            </div>

            {
              typeProduct.id === 1 ?
                <React.Fragment>
                  {
                    more ?
                    <div className="col-7 d-flex row">
                      <div className="col-1"/>
                      <div className="col-9 provision-list">
                        <h2 style={{"color": "#cacaca"}} >{typeProduct.title ? typeProduct.title : null}</h2>
                        <div className="row mb-4" style={{"fontWeight" : "bold"}}>
                          <div className="col-3">
                            Артикул
                          </div>
                          <div className="col-3">
                            Наименнование
                          </div>
                          <div className="col-3">
                            Кол-во
                          </div>
                          <div className="col-3">
                            Сумма про-ва
                          </div>
                        </div>
                      {children.map(item => {
                      return (
                        <div className="row mb-2" key={item.id}>
                          <div className="col-3">
                            {item.sku}
                          </div>
                          <div className="col-3">
                            {item.langList[0].product_title}
                          </div>
                          <div className="col-3">
                            {item.amount}
                          </div>
                          <div className="col-3 justify-content-center">
                            {item.sumProductionAll}
                          </div>
                        </div>)
                    })}
                    </div>
                    <div className="col-2">
                    </div>
                  </div>
                  : null }
                  <div className="product-details-btn">
                    <Button mode="small" onClick={() => setMore(!more)} text="Больше" />
                  </div>
                </React.Fragment>
              : null
            }
          </div>

          <Table
            product={product}
            prices={prices}
            updatePrice={updatePrice}
            deletePrice={deletePrice}
            idArr={idArr}
            addId={addId}
            updateProduct={updateProduct}
            regionList={regionList}
            productId={product.id}
            deleteRegion={deleteRegion}
            updateRegion={updateRegion} />
        </div>
    </div>
    </React.Fragment>
  );
};

export default Product;