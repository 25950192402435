export default class apiOrders {

  _apiBase = `https://api.plusobject.com/v1/`;
  currencyId = `?currency_id=${localStorage.currencyId}`
  async getResourse(url) {
    const res = await fetch(`${this._apiBase}${url}${this.currencyId}`);

    if(!res.ok) {
      throw new Error(`Could not fetch ${this._apiBase}` +
      `, received ${res.status}`)
    }
    return await res.json();
  };

  async postResourseAdd(url, data, controller) {
    const res = await fetch(`${this._apiBase}${url}${this.currencyId}`, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, cors, *same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
          'Origin': '*',
          'Content-Type': 'application/json',
          // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      redirect: 'follow', // manual, *follow, error
      referrer: 'no-referrer', // no-referrer, *client
        body: JSON.stringify(data),
    }, controller);
    if(!res.ok) {
      throw new Error(`Could not fetch ${url}` +
      `, received ${res.status}`)
    }
    return await res.json();
  };

  getOrder = (id) => {
    return this.getResourse(`orders/${id}`);
  };

  getOrderList = () => {
    return this.getResourse('orders');
  };

  deleteOrder = (id) => {
    return this.getResourse(`orders/delete/${id}`)
  };

  addOrder = (currency_id, region_id, lang_id, price_types, description, products) => {
    return this.postResourseAdd('orders', {
      currency_id,
      region_id,
      lang_id,
      price_types,
      products,
      description
    });
  };

  editPrice = (id,currency_id, region_id, lang_id, price_types, products, description) => {
    return this.postResourseAdd(`prices/update/${id}`, {
      currency_id,
      region_id,
      lang_id,
      price_types,
      products,
      description
    });
  };
};

// const api = new apiOrders();
// api.getOrderList().then(orders => console.log(orders))

const oreder = {
  currency_id: 980,
  region_id: 1,
  lang_id: 1,
  price_types: 1,
  description: "text",
  products: [
    {
      id: 2,
      count: 3,
      sum: 1200
    },
    {
      id: 23,
      count: 10,
      sum: 135
    }
  ],
};