
export default class ApiProducts {
  _apiBase = `https://api.plusobject.com/v1/`;
  currencyId = `currency_id=${localStorage.currencyId}`
  async getResourse(url, controller, currency_id ) {
    const currency = currency_id ? `currency_id=${currency_id}` :  this.currencyId;
    const res = await fetch(`${this._apiBase}${url}${currency}`, controller);

    if(!res.ok) {
      throw new Error(`Could not fetch ${url}` +
      `, received ${res.status}`)
    }
    return await res.json();
  };

  async getResourseAdd(url,data, controller) {
    const res = await fetch(`${this._apiBase}${url}${this.currencyId}`, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, cors, *same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
          'Origin': '*',
          'Content-Type': 'application/json',
          // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      redirect: 'follow', // manual, *follow, error
      referrer: 'no-referrer', // no-referrer, *client
        body: JSON.stringify(data),
    }, controller);
    if(!res.ok) {
      throw new Error(`Could not fetch ${url}` +
      `, received ${res.status}`)
    }
    return await res.json();
  };

  getProductList = (region_id, signal, currencyId) => {
    return this.getResourse(`products?region_id=${region_id}&`, signal, currencyId);
  };
  getProduct = (id, signal) => {
    return this.getResourse(`products/${id}?`, signal);
  };
  searchProduct = (str, controller, region_id) => {
    if(!str) return this.getResourse(`products?`, controller)
    return this.getResourse(`products/search/${str}?region_id=${region_id}&`, controller)
  };

  deleteProduct = (id, region_id) => {
    return this.getResourse(`products/delete/${id}?region_id=${region_id}&`)
  };

  addProduct = (name,nameSecondValue, nameThirdValue, sku, weight, height, deep, width, region_id, type_id, category_id, child) => {
    return this.getResourseAdd(`products?region_id=${region_id}&`,
    { lang_list: [
      {
        lang_id: 1,
        content: name
      },
      {
        lang_id: 2,
        content: nameSecondValue
      },
      {
        lang_id: 3,
        content: nameThirdValue
      },
    ],
    sku: sku, weight: weight, height, deep, width, category_id, type_id, child})
  };

  editProduct = (id,name, nameSecondValue, nameThirdValue, sku, weight, height, deep, width,  type_id, category_id, child) => {
    return this.getResourseAdd(`products/update/${id}?`,
      {
        lang_list: [
          {
            lang_id: 1,
            content: name
          },
          {
            lang_id: 2,
            content: nameSecondValue
          },
          {
            lang_id: 3,
            content: nameThirdValue
          }],
          sku: sku, weight: weight, height, deep, width,  type_id, category_id, child})
  };

  getPrices = (signal) => {
    return this.getResourse(`products/prices?`, signal);
  };

  getExpProductItem = (id, signal) => this.getResourse(`products/${id}/expenses?type=1&`, signal);
  getExpTransportItem = (id, idRegion, signal) => this.getResourse(`products/${id}/expenses?type=2&region_id=${idRegion}&`, signal);

  editExpensesProduction = (idProduct, idExp, price, currency, type, signal) => {
    return this.getResourseAdd(`products/${idProduct}/update-expenses/${idExp}?type=${type}&`,
      {price: price, currency_id: currency}, signal)
  };

  editExpensesTransport = (idProduct, idExp, price, currency, type, idRegion, signal) => {
    return this.getResourseAdd(`products/${idProduct}/update-expenses-region/${idExp}?type=${type}&`,
      {price: price, currency_id: currency, region_id: idRegion }, signal)
  };

  deleteExp = (idProduct, idExp, idRegion, type) => {
    return this.getResourse(`products/${idProduct}/delete-expense/${idExp}?type=${type}&region_id=${idRegion}&`);
  };

  deleteExpProduct = (idProduct, idExp, type) =>  this.getResourse(`products/${idProduct}/delete-expense/${idExp}?type=${type}&`);

  addExpProduct = (currency_id, price, idProduct, idExp, type, idRegion, valueDescriprion, number) => {
    return this.getResourseAdd(`products/${idProduct}/add-expenses/${idExp}?type=${type}&`,
    {
      currency_id: currency_id,
      price: price,
      region_id: idRegion,
      description: valueDescriprion,
      number
    }
    );
  };

  updatePriceProduct = (idProduct, region_id, price_type, precent, price) => {
    return this.getResourseAdd(`products/${idProduct}/prices-params?`, {
      currency_id: localStorage.currencyId,
      region_id,
      price_type,
      precent,
      price
    })
  };
};

// https://api.plusobject.com/v1/products/2/expenses?type=1&currency_id=978

// const api = new ApiProducts();
// api.editExpensesProduction(2,1,20,980).then(res => console.log(res));

// api.getExpProductItem(2).then(res => console.log(res));

// api.addProduct('products', {title: "test", price: 22});

// api.getProduct(2).then(res => console.log(res));
// api.searchProduct('1').then(res => console.log(res));
