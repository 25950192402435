import React, {useContext} from "react";

import { Link } from "react-router-dom";
import { CurrencyContext } from '../../context/currencyContext';
import CurrencySelect from './CurrencySelect';
import Logo from "./Logo";
import Nav from "./Nav";
import "./Header.sass";

const pageList = [
  {
    id: 0,
    name: "Справочники",
    link: '/direcory',
    subPage: [
      {
        id: 0,
        name: "Расходы",
        link: '/costs/production',
      },
      {
        id: 1,
        name: "Регионы",
        link: '/regions',
      },
      {
        id: 2,
        name: "Валюты",
        link: '/currency',
      },
      {
        id: 3,
        name: "Языки",
        link: '/langs',
      },
      {
        id: 4,
        name: "Типы продуктов",
        link: '/product-type',
      },
      {
        id: 5,
        name: "Каегории товаров",
        link: '/category',
      },
    ]
  },
  {
    id: 1,
    name: "Товары",
    link: '/products',
  },
  {
    id: 2,
    name: "Прайс-лист",
    link: '/price-list',
  },
  {
    id: 3,
    name: "Заказы",
    link: '/orders',
  }
];

const Header = () => {
  const currency = useContext(CurrencyContext);
  return (
    <header className="header shadow-box d-flex align-items-center justify-content-between">
      <Link to="/">
        <Logo />
      </Link>
      <CurrencySelect currency={currency}  />
      <Nav list={pageList}/>
    </header>
  );
};
export default Header;
