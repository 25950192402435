import React from 'react';

import Table from '../Table';
import './Transports.sass';

const Transports = () => {
  return(
    <div className='inner'>
      <Table typeExp={2} />
    </div>
  );
};
export default Transports;