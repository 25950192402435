import React from 'react';

const PriceSelect = ({ prices, selectCurrency, activeCurrency }) => {
  return (
    <div className="currency-list shadow-box">
      {prices.map(item =>
        <span onClick={() =>{
          selectCurrency(item.id)
          activeCurrency(item.id);
        }}
          key={item.id}>{item.sign ? item.sign : item.currency_short}</span>)}
    </div>
  );
};

export default PriceSelect;