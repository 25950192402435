import React from 'react';

import './Currency.sass';
import Table from './Table';

const Currency = () => {
  return (
    <div className="currency inner d-flex flex-column">
      <h1>Справочник Валют</h1>
      <Table />
    </div>
  );
};

export default Currency;