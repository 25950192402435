export default class ApiExpenses {
  _apiBase = `https://api.plusobject.com/v1/`;
  currencyId = `?currency_id=${localStorage.currencyId}`
  async getResourse(url) {
    const res = await fetch(`${this._apiBase}${url}${this.currencyId}`);
    if(!res.ok) {
      throw new Error(`Could not fetch ${url}` +
      `, received ${res.status}`)
    };
    return await res.json();
  };

  async getResourseDelete(url) {
    const res = await fetch(`${this._apiBase}${url}${this.currencyId}`, {
      method: 'DELETE',
      headers: {'content-type': 'application/json'},
    });
    if(!res.ok) {
      throw new Error(`Could not fetch ${url}` +
      `, received ${res.status}`)
    }
    return await res.json();
  };

  async getResourseAdd(url,data) {
    const res = await fetch(`${this._apiBase}${url}${this.currencyId}`, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, cors, *same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
          'Content-Type': 'application/json',
          // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      redirect: 'follow', // manual, *follow, error
      referrer: 'no-referrer', // no-referrer, *client
        body: JSON.stringify(data),
    });
    if(!res.ok) {
      throw new Error(`Could not fetch ${url}` +
      `, received ${res.status}`)
    }
    return await res.json();
  };

  async getResourseEdit(url, data) {
    const res = await fetch(`${this._apiBase}${url}${this.currencyId}`, {
      method: 'PUT', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, cors, *same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
          'Content-Type': 'application/json',
          // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      redirect: 'follow', // manual, *follow, error
      referrer: 'no-referrer', // no-referrer, *client
        body: JSON.stringify(data),
    });
    if(!res.ok) {
      throw new Error(`Could not fetch ${url}` +
      `, received ${res.status}`)
    }
    return await res.json();
  };

  getExpenses = (id) => {
    return this.getResourse(`expenses?type=${id}`);
  };

  getExpensesFull = () => {
    return this.getResourse(`expenses`);
  };

  getTypesExpenses = () => {
    return this.getResourse('expenses/types');
  };

  getTypesProduct = () => {
    return this.getResourse('product/types');
  };

  searchExpenses = (str, typeId, controller) => {
    if (!str) {
      return this.getResourse(`expenses?type=${typeId}`, controller);
    } else {return this.getResourse(`expenses/search/${str}`, controller)}
  };

  deleteExpenses = (id) => {
    return this.getResourseDelete(`expenses/${id}`);
  };

  addExpenses = (title, number, type, description) => {
    return this.getResourseAdd(`expenses`, {number, title, type, description});
  };

  editExpenses = (number, title, id, description) => {
    return this.getResourseEdit(`expenses/${id}`, {number, title, description});
  };
};