import React, { useState } from 'react';

import apiCategories    from '../../service/apiCategories';
import Input            from '../../components/Input';
import Button           from '../../components/Button';

const AddCategories = ({close, update}) => {

  const [ nameValue, setName ] = useState('');

  const handleSubmit = e => e.preventDefault();
  const handleName = (value) => setName(value);

  const addItem = () => {
    const api = new apiCategories();
    api.addCategory(nameValue).then(res => {
      update(res);
    });
  };

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit} className="form form-add shadow-box">
        <h2>Добавить категорию.</h2>
        <div className="row align-content-center">
          <div className="col-10">
            <Input
              value={nameValue}
              placeholder={"Введите название"}
              changeValue={handleName} />
          </div>
          <div className="col-2">
            <Button mode="small" text="Добавить" onClick={() => {addItem();close()}}/>
          </div>
        </div>
      </form>
    </React.Fragment>
  );
};
export default AddCategories;