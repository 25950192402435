import React, { useEffect, useState } from 'react';

import ApiExpenses from '../../../../service/apiResource';
import apiCurrency from '../../../../service/apiCurrency';
import ApiProducts from '../../../../service/apiProducts';
import Input from '../../../../components/Input';
import Select from '../../../../components/Select';
import Button from '../../../../components/Button';

const AddExp = ({typeExp, productId, update, idRegion}) => {

  const [ expList, setExpList ]                         = useState([]);
  const [ currency, setCurrency ]                       = useState([]);
  const [ activeItemExp, setActiveItemExp ]             = useState({title: 'Выберите расход'});
  const [ activeItemCurrency, setActiveItemCurrency ]   = useState({title: 'Выберите валюту'});
  const [ valuePrice, setPrice ]                        = useState();
  const [ valueDescriprion, setDescription ]            = useState('');
  const [ number, setNumber ]                           = useState(0);

  useEffect(() => {
    const api = new ApiExpenses();
    api.getExpenses(typeExp).then(res => setExpList(res));
  }, [typeExp]);

  useEffect(() => {
    const api = new apiCurrency();
    api.getCurrency().then(res => setCurrency(res));
  }, []);

  const changePrice = (value) => setPrice(value);
  const changeNumber = (value) => setNumber(value);
  const changeDescription = (value) => setDescription(value);


  const addExp = () => {
    if (activeItemExp.title === 'Выберите расход') alert('Выберите расход');
    else {
      const api = new ApiProducts();
      api.addExpProduct(activeItemCurrency.id, valuePrice, productId, activeItemExp.id, typeExp, idRegion, valueDescriprion, number).then(res => update(res));
      setActiveItemExp({title: 'Выберите расход'});
      setActiveItemCurrency({title: 'Выберите валюту'});
      setPrice(0);
      setNumber(0);
      setDescription('');
    }
  };

  const handleSubmit = (event) => event.preventDefault();

  return (
    <form className="form-add-exp" onSubmit={handleSubmit}>

      <div className="row">

        <div className="col-2">
          <Input valueInput={number} placeholder={"Введите номер"} type="number" changeValue={changeNumber} />
        </div>

        <div className="col-2">
          <Select number={true} label='Выберите расход' onClick={(item) => {
            setActiveItemExp(item);
            setNumber(item.number);
          }} itemsList={expList} activeItem={activeItemExp} />
        </div>

        <div className="col-2">
          <Input valueInput={valuePrice} placeholder={"Введите цену"} type="number" changeValue={changePrice} />
        </div>

        <div className="col-2">
          <Select  label='Выберите валюту' onClick={(item) => setActiveItemCurrency(item)} itemsList={currency} activeItem={activeItemCurrency} />
        </div>

        <div className="col-2">
          <Input valueInput={valueDescriprion} placeholder={"Введите комментарий"} changeValue={changeDescription} />
        </div>

        <div className="col-2">
          <Button text="Добавить" onClick={addExp} />
        </div>
      </div>
    </form>
  );
};

export default AddExp;