import React from "react";

import "./Logo.sass";

const Logo = () => {
  return (
    <div className="logo">
     <svg xmlns="http://www.w3.org/2000/svg" width="112" height="35" viewBox="0 0 112 35"><path d="M20.4 8.1C13.7 8 9 14.5 11.5 20.8c1.8 4.6 7.1 6.9 11.9 5.4 4.8-1.5 7.4-6.2 6.2-11-1-4.5-5.1-7.2-9.2-7.1zm.1 14.8c-3.1 0-5.5-2.4-5.5-5.6 0-3.1 2.4-5.6 5.5-5.6 3 0 5.4 2.5 5.4 5.6 0 3.1-2.4 5.6-5.4 5.6zM59.6.2h-4.1v4.2h4.1V.2zM12.3 3.9H8.5V.2H4.4v3.7H.5v3.7h3.9v3.7h4.1V7.6h3.9V3.9zm99.2 3.9V4h-4.2V.2h-4.2v19.5c0 .8 0 1.6.1 2.4.5 3.7 4.1 5.6 7.6 4.2v-3.4h-1.1c-1.7 0-2.4-.6-2.4-2.2-.1-2.6 0-12.8 0-12.8h4.2zM67.6 9.3c-2.7 1.5-4 4-4.3 7-.4 3.7.9 7.9 5.3 9.6 2.4.9 4.8 1 7.3.2 1.5-.5 2.8-1.3 3.9-2.6-.8-.8-1.6-1.5-2.4-2.3-.4.3-.7.5-1 .7-2.1 1.4-4.3 1.6-6.5.6-1.7-.7-2.6-2.3-2.5-4.1h13.5c.3-2.9-.2-5.4-2-7.4-2.8-3.1-7.6-3.8-11.3-1.7zm-.2 6.2c.1-2.2 2.3-3.9 4.9-3.9 2.5 0 4.4 1.6 4.4 3.9h-9.3zM42.5 8.1c-2.1 0-4 .6-5.5 1.6V.2h-4v17.2c0 1.1.2 2.3.7 3.4 1.8 4.6 7.1 6.9 11.9 5.4 4.8-1.5 7.4-6.2 6.2-11-1-4.5-5.2-7.2-9.3-7.1zm.1 14.8c-3.1 0-5.5-2.4-5.5-5.6 0-3.1 2.4-5.6 5.5-5.6 3 0 5.4 2.5 5.4 5.6 0 3.1-2.4 5.6-5.4 5.6zm49.4-11c2.2-.5 4 .3 5.7 1.7.8-.9 1.6-1.7 2.4-2.5-3.5-3.9-10.1-4-13.8-.2-3.7 3.7-3.5 10.1.6 13.5 3.9 3.3 10.4 2.8 13.2-.8-.8-.8-1.7-1.6-2.5-2.4-3.2 2.8-6.7 1.8-8.3.2-1.9-1.9-2.1-5.2-.5-7.4.8-1.2 1.9-1.8 3.2-2.1zM55.5 8.6v19.2s0 1.2-.1 1.8c-.1.9-.7 1.5-1.6 1.6-.5.1-1 0-1.6 0v3.6c1.5.3 2.8.2 4.2-.2 1.7-.6 3.3-2.3 3.3-5.7V8.6h-4.2z"/></svg>
    </div>
  );
};
export default Logo;
