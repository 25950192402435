import React, { useEffect, useState } from 'react';

import ApiProducts from '../../../../../service/apiProducts';

import AddExp from '../AddExp';
import PriceProductExp from '../PriceProductExp';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import './ProductExp.sass';

const ProductExp = ({productId, product}) => {

  const [ expList, setExpList ]     = useState([]);
  const [ sum, setSum ]             = useState(null);

  useEffect(() => {
    const api = new ApiProducts();
    const controller = new AbortController();
    const signal = controller.signal;

    api.getExpProductItem(productId, signal).then(res => {setExpList(res.productionExpenses); setSum(res.sumProductionAll)});
    return () => {
      controller.abort();
    };
  }, [productId]);

  const updateExpList = (res) => {
    setExpList(res.productionExpenses);
    setSum(res.sumProductionAll);
  };

  const deleteExp = (idExp) => {
    const api = new ApiProducts();
    api.deleteExpProduct(productId, idExp, 1).then(data => updateExpList(data));
  };
  const addExpItem = (data) => setExpList(data.productionExpenses);
  return (
    <div className="form form-add shadow-box">
      <div className="d-flex justify-content-between align-items-center">
        <h2>Производственные расходы</h2>
        <h2>{product.sku}</h2>
        <h2>{product.title}</h2>
      </div>
      <div className="form-product-table">

        <AddExp update={addExpItem} typeExp={1} productId={productId} />
        <div className="table-content-border"></div>
        <div className="row">
          <div className="col-1">
            <h2 className="d-flex justify-content-center align-items-center">Номер</h2>
          </div>
          <div className="col-3">
            <h2 className="d-flex align-items-center">Наименование</h2>
          </div>
          <div className="col-4">
            <h2 className="d-flex align-items-center">Комментарий</h2>
          </div>
          <div className="col-2">
            <h2 className="d-flex justify-content-center align-items-center">Цена</h2>
          </div>
          <div className="col-2">
            <h2 className="d-flex justify-content-center align-items-center">Удалить</h2>
          </div>
        </div>

        <div className="row">
          {
            expList.map(item => {
              return (
                <React.Fragment key={item.id}>
                  <div className="col-1 d-flex  justify-content-center  align-items-center">
                    <div className="form-product-table-number d-flex justify-content-center align-items-center">{item.number}</div>
                  </div>

                  <div className="col-3 d-flex align-items-center">
                    <div className="form-product-table-number d-flex align-items-center">{item.title}</div>
                  </div>

                  <div className="col-4 d-flex align-items-center">
                    <div className="form-product-table-number d-flex align-items-center tooltip">
                      <div className="description">{item.description? item.description : "----"}</div>
                      {item.description ? <div className="tooltiptext">{item.description}</div> : null}
                    </div>
                  </div>

                  <div className="col-2  d-flex justify-content-center align-items-center">
                    <PriceProductExp type={1} updateExpList={updateExpList} productId={productId} item={item} />
                  </div>

                  <div className="col-2  d-flex justify-content-center align-items-center">
                    <div className="text-center form-product-table-input" onClick={() => deleteExp(item.id)}>
                      <DeleteForeverIcon />
                    </div>
                  </div>
              </React.Fragment>
              )
            })
          }
        </div>

        <div className="table-content-border"></div>
        <div className="row sum-row">
          <h3 className="col-2 d-flex justify-content-center align-items-center">Сумма :</h3>
          <div className="col-7 d-flex justify-content-center align-items-center"></div>
          <h3 className="col-3 d-flex justify-content-center align-items-center">{sum} { expList.length ? expList[0].currency_short: null }</h3>
        </div>

      </div>
    </div>
  );
};

export default ProductExp;