import React, { useEffect, useState } from 'react';


import apiCurrency from '../../../service/apiCurrency';
import TableHeader from '../TableHeader';
import ModalWindow from '../../../components/ModalWindow';
import AddCurrency from '../AddCurrency';
import PanelSearch from '../../../components/PanelSearch';

import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import './Table.sass';
import EditCurrency from '../EditCurrency';



const Table = () => {

  const [ currency, setCurrency ] = useState([]);
  const [ activeAdd, setActiveAdd ] = useState(false);
  const [ activeEdit, setActiveEdit ] = useState(false);
  const [ itemEdit, setItemEdit] = useState('');
  const [ searchList, setSearchList ] = useState([]);

  useEffect(() => {
    const api = new apiCurrency();
    api.getCurrency().then(res => setCurrency(res));
  }, []);

  useEffect(() => {
    setCurrency( searchList );
  }, [ searchList ]);

  const searchChange = (list) => setSearchList(list);
  const updateAdd = (list) => setCurrency(list);
  const deleteItem = (id) => {
    const api = new apiCurrency();
    api.deleteCurrency(id).then(res => setCurrency(res))
  };

  const closeAdd = () => setActiveAdd(false);
  const open = () => setActiveAdd(true);
  const closeEdit = () => setActiveEdit(false);
  const openEdit = (item) => {
    setItemEdit(item);
    setActiveEdit(true);
  };

  const expListItem = currency.map(item => {
    return (
      <div className="regions row" key={item.id}>
        <div onClick={() => openEdit(item)} className="col-2 d-flex justify-content-center regions-item">
          {item.sign}
        </div>
        <div onClick={() => openEdit(item)} className="col-6 regions-item">
          {item.currency}
        </div>
        <div onClick={() => openEdit(item)} className="col-2 regions-item">
          {item.course}
        </div>
        <div
          onClick={() => deleteItem(item.id)}
          className="col-2 regions-item text-center d-flex justify-content-center">
          <div className="text-center">
            <DeleteForeverIcon />
          </div>
        </div>
      </div>
    );
  });

  return (
    <div className="table">
      {activeAdd ?
        <ModalWindow close={closeAdd}>
          <AddCurrency
            update={updateAdd}
            close={closeAdd}/>
        </ModalWindow> : null}

      {activeEdit ?
      <ModalWindow close={closeEdit}>
        <EditCurrency
          item={itemEdit}
          update={updateAdd}
          close={closeEdit}/>
      </ModalWindow> : null}

      <PanelSearch
        type="currency"
        searchList={searchChange}
        setActiveAdd={open}/>

      <div className="table-content shadow-box">
        <TableHeader />
        <div className="table-content-border"></div>
        {expListItem}
      </div>
    </div>
  );
};

export default Table;
