import React from 'react';

import './Additional.sass';


const Additional = () => {
  return(
    <div className='inner'>
      Дополнительные расходы
    </div>
  );
};
export default Additional;