import React, { useState } from 'react';

import apiCurrency from '../../../service/apiCurrency';

import Input from '../../../components/Input';
import Button from '../../../components/Button';

const AddCurrency = ({close, update}) => {

  const [ nameValue, setName ]              = useState('');
  const [ currencyShort, setCurrencyShort]  = useState('');
  const [ idCurrency, setIdCurrency ]       = useState(null);
  const [ signCurrency, setSignCurrency ]   = useState(null);
  const [ course, setCourse ]               = useState();

  const handleSubmit = e => e.preventDefault();
  const handleName = (value) => setName(value);
  const handleIdCurrency = (value) => setIdCurrency(value);
  const handleSignCurrency = (value) => setSignCurrency(value);
  const handleCurrencyShort = (value) => setCurrencyShort(value);
  const gandleCourse = (value) => setCourse(value);

  const addItem = () => {
    const api = new apiCurrency();
    api.addCurrency(nameValue, idCurrency , currencyShort, signCurrency, course).then(res => {
      update(res);
    });
  };


  return (
    <React.Fragment>
      <form onSubmit={handleSubmit} className="form form-add shadow-box">
        <h2>Добавить новую валюту в список валют</h2>
        <div className="row align-content-center">

          <div className="col-2">
            <Input value={nameValue} placeholder={"Введите название"} changeValue={handleName} />
          </div>

          <div className="col-2">
            <Input value={idCurrency} type="number" placeholder={"Введите id"} changeValue={handleIdCurrency} />
          </div>

          <div className="col-2">
            <Input
              value={currencyShort}
              placeholder={"Введите краткое название"}
              changeValue={handleCurrencyShort} />
          </div>

          <div className="col-2">
            <Input
              value={signCurrency}
              placeholder={"Введите знак валюты"}
              changeValue={handleSignCurrency} />
          </div>

          <div className="col-2">
            <Input
              type="number"
              value={course}
              placeholder={"Введите курс"}
              changeValue={gandleCourse} />
          </div>

          <div className="col-2">
            <Button mode="small" text="Добавить" onClick={() => {addItem();close()}}/>
          </div>
        </div>
      </form>
    </React.Fragment>
  );
};
export default AddCurrency;