import React from 'react';

import './TableHeader.sass';

const TableHeader = () => {
  return (
    <div className="name-titles row">
      <div className="col-2 d-flex justify-content-center title">
        Валюта
      </div>
      <div className="col-2 title">
        Наименование
      </div>
      <div className="col-6 title">
        Комментарий
      </div>
      <div className="col-2 col-xl-2 title justify-content-center d-flex">
        Удалить
      </div>
    </div>
  );
};

export default TableHeader;