import React from "react";
import { BrowserRouter as Router,Route } from "react-router-dom";
import {Context} from '../../context/currencyContext';

import Header         from "../Header";
import Costs          from "../../pages/Costs";
import Production     from "../../pages/Costs/Production";
import Transports     from "../../pages/Costs/Transports";
import Additional     from "../../pages/Costs/Additional";
import Products       from "../../pages/Products";
import Product        from "../../pages/Products/Product";
import PriceList      from "../../pages/PriceList";
import Orders         from "../../pages/Orders";
import Regions        from "../../pages/Regions";
import Currency       from "../../pages/Currency";
import Langs          from "../../pages/Langs";
import ProductType    from "../../pages/ProductType";
import Categories     from "../../pages/Categories";

import "./App.sass";

const App = () => {
  return (
    <Context>
      <Router>
        <div className="app">
          <Header  />
          <Route exact path="/">
            <Products />
          </Route>
          <Route path="/costs">
            <Costs />
          </Route>
            <Route path="/costs/production">
              <Production />
            </Route>
            <Route path="/costs/transports">
              <Transports />
            </Route>
            <Route path="/costs/additional">
              <Additional />
            </Route>
          <Route path="/products" exact>
            <Products />
          </Route>
            <Route path='/products/:id'>
              <Product />
            </Route>
          <Route path="/price-list">
            <PriceList />
          </Route>
          <Route path="/orders">
            <Orders />
          </Route>
          <Route path="/currency">
            <Currency />
          </Route>
          <Route path="/regions">
            <Regions />
          </Route>
          <Route path="/langs">
            <Langs />
          </Route>
          <Route path="/product-type">
            <ProductType />
          </Route>
          <Route path="/category">
            <Categories />
          </Route>
        </div>
      </Router>
    </Context>
  );
};
export default App;