import React, { useState, useEffect } from 'react';

import Input from '../../../components/Input';
import Button from '../../../components/Button';
import apiRegions from '../../../service/apiRegions';
import apiCurrency from '../../../service/apiCurrency';
import apiLangs from '../../../service/apiLangs';
import Select from '../../../components/Select';


const EditRegion = ({close, update, item}) => {

  const [ nameValue, setName ]                          = useState(item.title);
  const [ descriptionValue, setDescription]             = useState(item.description);
  const [ currency, setCurrency ]                       = useState([]);
  const [ langs, setLangs ]                             = useState([]);
  const [ activeItemCurrency, setActiveItemCurrency ]   = useState({currency :item.currency_title, id:item.currency_id});
  const [ activeItemLang, setActiveItemLang ]           = useState({title: 'Выберите язык'});

  const handleSubmit = e => e.preventDefault();
  const handleName = (value) => setName(value);
  const handleDescription = (value) => setDescription(value);

  const updateRegion = () => {
    const api = new apiRegions();
    api.editRegion(nameValue, item.id, activeItemCurrency.id, descriptionValue, activeItemLang.id).then(res => update(res));
  };

  useEffect(() => {
    const api = new apiCurrency();
    api.getCurrency().then(res => setCurrency(res));
  }, []);

  useEffect(() => {
    const api = new apiLangs();
    api.getLangs().then(langs => setLangs(langs));
  }, []);

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit} className="form form-add shadow-box">
        <h2>Изменить регион в списке регионов.</h2>
        <div className="row align-content-center">

          <div className="col-2">
            <Select label='Выберите валюту'
              onClick={(item) => setActiveItemCurrency(item)}
              itemsList={currency}
              activeItem={activeItemCurrency}/>
          </div>

          <div className="col-2">
            <Select label='Выберите язык'
              onClick={(item) => setActiveItemLang(item)}
              itemsList={langs}
              activeItem={activeItemLang}/>
          </div>

          <div className="col-2">
            <Input
              defaultValue={item.title}
              value={nameValue}
              placeholder={"Введите название"}
              changeValue={handleName} />
          </div>

          <div className="col-3">
            <Input
              defaultValue={item.description}
              value={descriptionValue}
              placeholder={"Введите комментарий"}
              changeValue={handleDescription} />
          </div>

          <div className="col-3">
            <Button mode="small" text="Изменить" onClick={() => {updateRegion();close()}}/>
          </div>
        </div>
      </form>
    </React.Fragment>
  );
};
export default EditRegion;