import React from 'react';

import './TableHeader.sass';

const TableHeader = () => {
  return (
    <div className="name-titles row">
      <div className="col-10 title">
        <div className="ml-4">Наименование</div>
      </div>
      <div className="col-2 col-xl-2 title justify-content-center d-flex">
        Удалить
      </div>
    </div>
  );
};

export default TableHeader;