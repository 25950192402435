import React from 'react';
import Table from './Table';

const ProductType = () => {
  return (
    <div className="regions inner d-flex flex-column">
      <h1>Справочник Типов продуктов</h1>
      <Table />
    </div>
  );
};

export default ProductType;