import React, {useState} from 'react';

import { Link } from "react-router-dom";
import './NavSelect.sass';

const NavSelect = ({name,links}) => {

  const [ show, toggleShow ] = useState(false);

  return (
    <div className="nav-select">
      <div onClick={() => toggleShow(!show)} className="nav-select-tab">{name}</div>
      {
        show ? <div className="nav-select-menu">
            {links.map(l => {
              return <Link onClick={() => toggleShow(!show)} to={l.link} key={l.id}>{l.name}</Link>
            })}
          </div> : null
      }
    </div>
  );
};

export default NavSelect;