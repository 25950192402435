import React from 'react';

const TableHeader = () => {
  return (
    <div className="name-titles row">
      <div className="col-2 d-flex justify-content-center title">
        №
      </div>
      <div className="col-2 title">
        Регион
      </div>
      <div className="col-2 title">
        Коментарий
      </div>
      <div className="col-2 title">
        Сумма
      </div>
      <div className="col-2 title justify-content-center d-flex">
        Скачать
      </div>
      <div className="col-2 col-xl-2 title justify-content-center d-flex">
        Удалить
      </div>
    </div>
  );
};

export default TableHeader;