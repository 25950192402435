import React, { useState, useEffect } from 'react';

import apiOrders from '../../service/apiOrders';
import apiProducts from '../../service/apiProducts';
import Select from '../../components/Select';
import Input from '../../components/Input';
import Button from '../../components/Button';
import SelectProduct from './SelectProduct';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

const TableHeader = () => {
  return (
    <div className="name-titles row">
      <div className="col-2 d-flex justify-content-center title">
        Артикул
      </div>
      <div className="col-3 title">
        Наименование
      </div>
      <div className="col-1 title">
        Кол-во
      </div>
      <div className="col-2 title d-flex justify-content-center">
        Цена
      </div>
      <div className="col-1 title">
        Сумма
      </div>
      <div className="col-1 title justify-content-center d-flex">
        Объем
      </div>
      <div className="col-1 title justify-content-center d-flex">
        Вес
      </div>
      <div className="col-1 title justify-content-center d-flex">
        Удалить
      </div>
    </div>
  );
};

const List = ({ item, price, deleteItem, activeItemLang, handleSum, handleSumWeight, handleSumSize, updateProduct, products }) => {

  const [ count, setCount ]  = useState(1);
  const [ sum, setSum ]      = useState(0);

  const handleCount = (e) => {
    if (e.target.value < count && e.target.value >= 1 ) {
      setSum(sum - price.price);
      handleSum( price.price, "dicr");
      handleSumWeight( item.weight, "dicr");
      handleSumSize( item.size, "dicr");
    } else if (e.target.value > count && e.target.value > 1) {
      handleSum( price.price, "inc");
      handleSumWeight( item.weight, "inc");
      handleSumSize( item.size, "inc");
    };
    if (e.target.value < 1) {
      setCount(1);
    } else {
      setCount(e.target.value)
    };
  };

  useEffect(() => {
    let newProducts = products;
    newProducts.forEach(element => {
      if(element.id === item.id) {
        element.sum = sum;
        element.count = count;
      };
      return
    });
    updateProduct(newProducts);
  }, [sum, count]);

  useEffect(() => { handleSumWeight(item.weight, "inc")}, []);

  useEffect(() => { handleSumSize(item.size, "inc")}, []);

  useEffect(() => { handleSum(price.price, "inc")}, []);

  useEffect(() => { setSum(price.price * count) }, [ count ]);

  return (
    <div key={item.id} className="d-flex regions">
      <div className="col-2 d-flex regions-item">
        {item.sku}
      </div>

      <div className="col-3 d-flex regions-item">
        {item.langList[activeItemLang.id - 1] ? item.langList[activeItemLang.id - 1].product_title : null}
      </div>

      <div className="col-1 d-flex regions-item">
        <input type="number" value={count} onChange={handleCount} />
      </div>

      <div className="col-2 d-flex regions-item d-flex justify-content-center">
        {price.price}
      </div>

      <div className="col-1 d-flex regions-item">
        {sum}
      </div>

      <div className="col-1 d-flex regions-item  d-flex justify-content-center">
        {item.size ? item.size : 0}
      </div>

      <div className="col-1 d-flex regions-item  d-flex justify-content-center">
        {item.weight ? item.weight : 0}
      </div>

      <div
        onClick={() => deleteItem(item.id)}
        className="col-1 regions-item text-center d-flex justify-content-center">
        <div className="text-center">
          <DeleteForeverIcon />
        </div>
      </div>
    </div>
  );
};

const AddOrder = ({ regions, langs, currency, orders, closeWindow, uploadItem }) => {

  const priceList = [
    {
      title: 'Мегаопт',
      id: 1
    },
    {
      title: 'Опт',
      id: 2
    },
    {
      title: 'Розница',
      id: 3
    },
  ];
  const [ products, setProducts ]                       = useState([]);
  const [ descriptionValue, setDescription ]            = useState('');
  const [ activeItemCurrency, setActiveItemCurrency ]   = useState({title: 'Выберите валюту'});
  const [ activeItemLang, setActiveItemLang ]           = useState({title: 'Выберите язык'});
  const [ activeItemRegion, setActiveItemRegion ]       = useState(regions[0]);
  const [ activePrice, setActivePrice ]                 = useState(priceList[0]);
  const [ addProduct, setAddProduct ]                   = useState({title: 'Выберите товар'});
  const [ allProducts, setAllProducts ]                 = useState([]);
  const [ sum, getSum ]                                 = useState(0);
  const [ sizeSum, getSizeSum ]                         = useState(0);
  const [ weightSum, getWeightSum ]                     = useState(0);

  const [ productAdd, setProductAdd ]                   = useState([]);

  const update = (products) => {
    setProductAdd(products);
  };

  const handleSum = (price, type) => {
    if(type === "inc") {
      getSum(sum + price);
    }
    if (type === "dicr") {
      getSum(sum - price)
    }
  };

  const handleSumWeight = (price, type) => {
    if(type === "inc") {
      getWeightSum(weightSum + price);
    }
    if (type === "dicr") {
      getWeightSum(weightSum - price)
    };
  };

  const handleSumSize = (price, type) => {
    if(type === "inc") {
      getSizeSum(sizeSum + price);
    }
    if (type === "dicr") {
      getSizeSum(sizeSum - price)
    };
  };

  const handleDescription                               = (value) => setDescription(value);
  const updateProductList                               = () => setProducts(allProducts);
  useEffect(() => {
    const api = new apiProducts();
    const controller = new AbortController();
    const signal = controller.signal;
    api.getProductList(activeItemRegion.id, signal, activeItemCurrency.id).then(products => setAllProducts(products));
  }, [ activeItemCurrency ]);
  useEffect(() => {
    if (activeItemRegion.title !== 'Выберите регион') {
      if (activeItemCurrency.title === 'Выберите валюту' && activeItemLang.title === 'Выберите язык') {
        setActiveItemCurrency(currency.find(item => item.id === activeItemRegion.currency_id));
        setActiveItemLang(langs.find(item => item.id === activeItemRegion.lang_id));
      };
    };
  }, [ activeItemRegion ]);
  const deleteItem = (id) => {
    setProducts(products.filter(item => item.id !== id));
  };

  const addOrder = () => {
    const api = new apiOrders();
    const productList =  productAdd.map(item => {
      return (
        {
          id: item.id,
          count: item.count,
          sum: item.sum
        }
      );
    });
    api.addOrder(
      activeItemCurrency.id,
      activeItemRegion.id,
      activeItemLang.id,
      activePrice.id,
      descriptionValue,
      [...productList]
    ).then(list => uploadItem(list));
  };

  return (
      <form onSubmit={e => e.preventDefault()} className="form form-add">
        <h2>Новый заказ</h2>
        <div className="row">

          <div className="col-2">
            <Select label='Выберите регион'
              onClick={(item) => setActiveItemRegion(item)}
              itemsList={regions}
              activeItem={activeItemRegion} />
          </div>

          <div className="col-2">
            <Select
              label='Выберите валюту'
              onClick={(item) => setActiveItemCurrency(item)}
              itemsList={currency}
              activeItem={activeItemCurrency} />
          </div>

          <div className="col-2">
            <Select
            label='Выберите язык'
            onClick={(item) => setActiveItemLang(item)}
            itemsList={langs}
            activeItem={activeItemLang} />
          </div>

          <div className="col-2">
            <Select
            label='Выберите цену'
            onClick={(item) => setActivePrice(item)}
            itemsList={priceList}
            activeItem={activePrice} />
          </div>

          <div className="col-2">
            <Input
              value={descriptionValue}
              placeholder={"Введите комментарий"}
              changeValue={handleDescription} />
          </div>

          <div className="col-2">
            <Button onClick={() => {
              updateProductList();
            }} text="Добавить все" />
          </div>

        </div>
        <div className="row mt-5">
          <div className="col-4">
            <SelectProduct label='Выберите товар'
              onClick={(item) => setProducts([...products, item])}
              itemsList={allProducts}
              activeItem={addProduct} />
          </div>
        </div>

        <div className="mt-4 table-content table shadow-box d-flex flex-column">
          <TableHeader />

          {products ? products.map(item => {
              const price = item.regionData[0].prices.find(item => item.name === activePrice.title);
              return <List
                      products={products}
                      updateProduct={update}
                      handleSumWeight={handleSumWeight}
                      handleSumSize={handleSumSize}
                      handleSum={handleSum}
                      key={item.id}
                      item={item}
                      activeItemLang={activeItemLang}
                      price={price}
                      deleteItem={deleteItem}/>
            }) : null}

          <div className="order-sum">Сумма : {sum} {activeItemCurrency.sign}</div>
          <div className="order-sum">Суммарный вес : {Math.round(weightSum)} кг</div>
          <div className="order-sum">Суммарный объем : {Math.round(sizeSum)} м<sup>3</sup></div>
        </div>

        <div className="row mt-4">
          <div className="col-3">
            <Button text="Создать заказ" onClick={() => {addOrder(); closeWindow()}} />
          </div>
        </div>

      </form>
  );
};

export default AddOrder;