import React, { useEffect, useState } from 'react';

import apiLangs from '../../../service/apiLangs';
import TableHeader from '../TableHeader';
import ModalWindow from '../../../components/ModalWindow';
import Button from '../../../components/Button';

import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import './Table.sass';
import AddLang from '../AddLang';

const Table = () => {
  const [ langs, setLangs ] = useState([]);
  const [ activeAdd, setActiveAdd ] = useState(false);

  useEffect(() => {
    const api = new apiLangs();
    api.getLangs().then(res => setLangs(res));
  }, []);

  const updateAdd = (list) => setLangs(list);
  const deleteItem = (id) => {
    const api = new apiLangs();
    api.deleteLang(id).then(res => setLangs(res))
  };

  const closeAdd = () => setActiveAdd(false);
  const open = () => setActiveAdd(true);

  const expListItem = langs.map(item => {
    return (
      <div className="regions langs row" key={item.id}>
        <div className="col-2 regions-item langs-item justify-content-center d-flex">
          {item.short}
        </div>
        <div className="col-8 regions-item">
          {item.title}
        </div>
        <div
          onClick={() => deleteItem(item.id)}
          className="col-2 regions-item text-center d-flex justify-content-center">
          <div className="text-center">
            <DeleteForeverIcon />
          </div>
        </div>
      </div>
    );
  });

  return (
    <div className="table d-flex flex-column">
      {activeAdd ?
        <ModalWindow close={closeAdd}>
          <AddLang
            update={updateAdd}
            close={closeAdd}/>
        </ModalWindow> : null}

      <div className="ml-auto mb-4">
        <Button text="Добавить" onClick={() => setActiveAdd(true)} />
      </div>

      <div className="table-content shadow-box">
        <TableHeader />
        <div className="table-content-border"></div>
        {expListItem}
      </div>
    </div>
  );
};

export default Table;
