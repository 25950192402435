import React, { useEffect, useState } from 'react';


import apiRegions from '../../../service/apiRegions';

import TableHeader from '../TableHeader';
import ModalWindow from '../../../components/ModalWindow';
import EditRegion from '../EditRegion';
import PanelSearch from '../../../components/PanelSearch';

import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import './Table.sass';
import AddRegion from '../AddRegion';



const Table = () => {

  const [ regions, setRegions ] = useState([]);
  const [ activeAdd, setActiveAdd ] = useState(false);
  const [ activeEdit, setActiveEdit ] = useState(false);
  const [ itemEdit, setItemEdit] = useState('');
  const [ searchList, setSearchList ] = useState([]);

  useEffect(() => {
    const api = new apiRegions();
    api.getRegions().then(res => setRegions(res));
  }, []);

  useEffect(() => {
    setRegions( searchList );
  }, [ searchList ]);

  const searchChange = (list) => setSearchList(list);
  const updateAdd = (list) => setRegions(list);
  const deleteItem = (id) => {
    const api = new apiRegions();
    api.deleteRegions(id).then(res => setRegions(res))
  };

  const closeAdd = () => setActiveAdd(false);
  const open = () => setActiveAdd(true);
  const closeEdit = () => setActiveEdit(false);
  const openEdit = (item) => {
    setItemEdit(item);
    setActiveEdit(true);
  };

  const expListItem = regions.map(item => {
    return (
      <div className="regions row" key={item.id}>
        <div onClick={() => openEdit(item)} className="col-2 d-flex justify-content-center regions-item">
          {item.currency_sign}
        </div>
        <div onClick={() => openEdit(item)} className="col-2 regions-item">
          {item.title}
        </div>
        <div onClick={() => openEdit(item)} style={{"whiteSpace" : "nowrap"}} className="col-6 tooltip regions-item">
          <div className="description">{item.description}</div>
          {item.description ? <div className="tooltiptext">{item.description}</div> : null}
        </div>
        <div
          onClick={() => deleteItem(item.id)}
          className="col-2 regions-item text-center d-flex justify-content-center">
          <div className="text-center">
            <DeleteForeverIcon />
          </div>
        </div>
      </div>
    );
  });

  return (
    <div className="table">
      {activeAdd ?
        <ModalWindow close={closeAdd}>
          <AddRegion
            update={updateAdd}
            close={closeAdd}/>
        </ModalWindow> : null}

      {activeEdit ?
        <ModalWindow close={closeEdit}>
          <EditRegion
            item={itemEdit}
            update={updateAdd}
            close={closeEdit}/>
        </ModalWindow> : null}

      <PanelSearch
        type="region"
        searchList={searchChange}
        setActiveAdd={open}/>

      <div className="table-content shadow-box">
        <TableHeader />
        <div className="table-content-border"></div>
        {expListItem}
      </div>
    </div>
  );
};

export default Table;
