import React, {useEffect, useState} from 'react';

import ApiProducts from '../../service/apiProducts';
import ApiExpenses from '../../service/apiResource';
import './PanelSearch.sass';

import Input from '../Input';
import Button from '../Button';
import apiRegions from '../../service/apiRegions';
import apiCurrency from '../../service/apiCurrency';

const PanelSearch = ({setActiveAdd, searchList, type, typeId, idRegion}) => {
  const [ searchValue , changeValue ] = useState('');
  const [data, setData] = useState([]);

  useEffect(() => {
    const api = new ApiProducts();
    const apiExp = new ApiExpenses();
    const apiRegion = new apiRegions();
    const apiCurrencys = new apiCurrency();
    const controller = new AbortController();
    const signal = controller.signal;

    switch (type) {
      case 'products':
        api.searchProduct(searchValue, signal, idRegion).then(res => setData(res));
        break;
      case 'expenses':
        apiExp.searchExpenses(searchValue, typeId, signal).then(res => setData(res.filter(item => item.type === typeId)));
        break;
      case 'region':
        apiRegion.searchRegions(searchValue, signal).then(res => setData(res));
        break;
      case 'currency':
        apiCurrencys.searchCurrency(searchValue, signal).then(res => setData(res));
        break;
      default:
        break;
    };

    return () => {
      controller.abort();
    };
  }, [ searchValue, setData, typeId, type ]);

  useEffect(() => {
    searchList(data);
  }, [data, searchList]);

  const changeName = (str) => changeValue(str);
  const handleSubmit = (evt) => evt.preventDefault();

  return(
    <div className="search-pannel row">
      <form onSubmit={handleSubmit} className="col-10">
        <Input type="icon" valueInput={searchValue} changeValue={changeName} placeholder="Поиск"  />
      </form>
      <div className="col-2">
        <Button onClick={() => setActiveAdd(true)} text="+" />
      </div>
    </div>
  );
};

export default PanelSearch;