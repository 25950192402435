import React, { useState, useEffect } from 'react';

import apiCurrency from '../../../../service/apiCurrency';
import ApiProducts from '../../../../service/apiProducts';

import PriceSelect from './PriceSelect';

const PriceProductExp = ({item, productId, updateExpList, type, idRegion }) => {

  const [ active, setActive ]                 = useState(false);
  const [ value, setValue ]                   = useState(item.price);
  const [ currencyList, setCurrencyList ]     = useState([]);
  const [ valueCurrency, setValueCurrency ]   = useState(localStorage.currencyId);
  const [ activeCerrency, setActiveCurrency ] = useState(item);

  const handleSubmit = e => e.preventDefault();
  const changeCurrency = (id) => setActiveCurrency(currencyList.find(item => item.id === id));

  const selectCurrency = (id) => setValueCurrency(id);

  const getCurrency = () => {
    const api = new apiCurrency();
    api.getCurrency().then(res => setCurrencyList(res));
  };

  useEffect(() => {
    const api = new ApiProducts();
    const controller = new AbortController();
    const signal = controller.signal;

    if(type === 1) {
      if(value !== item.price) api.editExpensesProduction( productId, item.id, value, valueCurrency, 1, signal )
      .then(res => updateExpList(res));
    } else {
      if(value !== item.price) api.editExpensesTransport( productId, item.id, value, valueCurrency, 2, idRegion, signal )
      .then(res => {
        updateExpList(res);
      });
    }
    return () => {
      controller.abort();
    };
  }, [value, productId, valueCurrency, item.id, item.price]);

  return (
    <div className="form-product-table-input d-flex justify-content-center align-items-center">

      <form onSubmit={handleSubmit}>
        <input
          onChange={(event) => setValue(event.target.value)}
          defaultValue={item.price} />
      </form>

      <div className="currency-list-exp" onClick={(e) => {
        getCurrency();
        setActive(!active);
      }}>
        <span>{activeCerrency.sign ? activeCerrency.sign : activeCerrency.currency_short}</span>
        { active ?
        <PriceSelect  activeCurrency={changeCurrency} selectCurrency={selectCurrency} prices={currencyList} />
        : null}
      </div>

    </div>
  )
};
export default PriceProductExp;
