import React, { useState } from 'react';

import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import BackupIcon from '@material-ui/icons/Backup';

const Order = ({item, deleteItem, uploadItem, update, regions, langs, currency}) => {

  const [ show, setShow ] = useState(false);
  return (
    <div key={item.id} className="row">
      <div
       onClick={() => setShow(true)}
       className="col-2 d-flex table-item-price justify-content-center">
        {item.id}
      </div>
      <div
        onClick={() => setShow(true)}
        className="col-2 table-item-price">
        {item.region_title}
      </div>
      <div
        onClick={() => setShow(true)}
        className="col-2 table-item-price">
        {item.description}
      </div>
      <div
        onClick={() => setShow(true)}
        className="col-2 table-item-price">
        {item.sum}
      </div>
      <div onClick={() => uploadItem(item.id)}
        className="col-2 d-flex justify-content-center">
        <a target="_blank" href={`https://api.plusobject.com/v1/orders/${item.id}/generate-pdf`} className="text-center">
          <BackupIcon />
        </a>
      </div>
      <div onClick={() => deleteItem(item.id)}
        className="col-2 d-flex justify-content-center">
        <div className="text-center">
          <DeleteForeverIcon />
        </div>
      </div>
    </div>
  )
};

export default Order;