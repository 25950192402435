import React, { useState } from 'react';

import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import './Select.sass';

const Select = ({ activeItem, itemsList, onClick, iconLeft = false, label, number }) => {

  const [ show, toggleShow ] = useState(false);
  return (
    <div className="select">
      <label>{label}</label>
      <div className="select-active shadow-box"
        onClick={() => toggleShow(!show)}>
        <div className="select-active-item">
          {iconLeft ? <div style={{marginRight: "6px"}}>
            <QueryBuilderIcon fontSize="small" />
          </div> : null }
          { activeItem.title ? activeItem.title : activeItem.currency}
        </div>
        <ExpandMoreRoundedIcon/>
      </div>
      {
        show ?
        <div className="select-list shadow-box">
          { itemsList.map((item) => <div onClick={() => {
            onClick(item);
            toggleShow(!show);
          }} key={ item.id }>
          {number ?  <span>{item.number} </span> : null}
          { item.title ? item.title : item.currency }
            </div>) }
        </div> : null
      }
    </div>
  );
};

export default Select;