import React, { useState, useEffect } from 'react';

import Input from '../Input';
import Button from '../Button';
import Select from '../Select';
import SelectProduct from '../SelectProduct';
import apiProducts from '../../service/apiProducts';
import apiProductType from '../../service/apiProductType';
import apiCategories from '../../service/apiCategories';

import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

import './AddProduct.sass';

const ListProvision = ({item, deleteItem, addItem,}) => {

  const [ count, setCount ] = useState(1);

  const handleCount = (e) => {
    if (e.target.value < 1) {
      const provision = {id: item.id, count: e.target.value}
      addItem(provision);
      setCount(1);
    } else {
      setCount(e.target.value);
      const provision = {id: item.id, count: e.target.value}
      addItem(provision);
    };
  };

  useEffect(() => {
    const provision = {id: item.id, count: 1}
    addItem(provision);
  }, []);

  return (
    <div className="row mb-2">
      <div className="col-3">
        {item.sku}
      </div>
      <div className="col-3">
        {item.langList[0].product_title}
      </div>

      <div className="col-3">
        <input type="number" value={count} onChange={handleCount} />
      </div>

      <div className="col-3 justify-content-center" onClick={() => deleteItem(item.id)}>
        <DeleteForeverIcon />
      </div>
    </div>
  );

};

const AddProduct = ({close, updateList, regionId}) => {

  const [ nameValue, setName ]                 = useState('');
  const [ nameSecondValue, setSecondName ]     = useState('');
  const [ nameThirdValue, setThirdName ]       = useState('');
  const [ types, setTypes ]                    = useState([]);
  const [ categoryes, setCategoryes ]          = useState([]);
  const [ numberValue, setNumber]              = useState('');
  const [ weightValue, setWeight ]             = useState('');
  const [ height, setHeight ]                  = useState();
  const [ deep, setDeep ]                      = useState();
  const [ width, setWidth ]                    = useState();

  const [ activeTypeProduct, setActiveTypeProduct ]       = useState({title: "Выберите тип продукта", id: 0});
  const [ activeCategory, setActiveTypeCategory ]         = useState({title: "Выберите категорию продукта", id: 0});

  const [ provision, setProvision ]                       = useState([]);
  const [ activeProvision, setActiveProvision ]           = useState({title: "Выберите изделие", id: 0});
  const [ productProvisionList, setProductProvisionList ] = useState([]);
  const [ postProvision, setPostProvision ]               = useState([]);

  const handleSubmit        = e => e.preventDefault();
  const handleName          = (value) => setName(value);
  const handleSecondName    = (value) => setSecondName(value);
  const handleThirdName     = (value) => setThirdName(value);
  const handleNumber        = (value) => setNumber(value);
  const handleWeight        = (value) => setWeight(value);
  const handleHeight        = (value) => setHeight(value);
  const handleDeep          = (value) => setDeep(value);
  const handleWidth         = (value) => setWidth(value);

  const addItem = () => {
    const api = new apiProducts();
    if (!numberValue) {
      alert("Введите артикул")
    } else {
      api.addProduct(nameValue,
        nameSecondValue,
        nameThirdValue,
        numberValue,weightValue,
        height, deep, width, regionId,
        activeTypeProduct.id,
        activeCategory.id,
        postProvision
        ).then(data => updateList(data));
      close();
    }
  };

  const deleteItemProvision = (id) => {
    setProductProvisionList(productProvisionList.filter(item => item.id !== id));
  };
  const addProvision = (item) => {
    setProductProvisionList([...productProvisionList.filter(e => e.id !== item.id), item]);
  };

  useEffect(() => {
    const api = new apiProductType();
    api.getProductType().then(types => setTypes(types));
  }, []);

  useEffect(() => {
    const api = new apiCategories();
    api.getCategories().then(types => setCategoryes(types));
  }, []);

  useEffect(() => {
    const api = new apiProducts();
    api.getProductList().then(products => {
      setProvision(products.filter(item => item.type_id === 5 || item.type_id === 4 ));
    })
  }, []);

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit} className="form product-edit form-add shadow-box d-flex flex-column">
        <h2>Добавить новый товар в список товаров.</h2>
        <div className="row align-content-center product-edit-item">
          <div className="col-3">
            <Input
              value={numberValue}
              placeholder={"Введите артикул"}
              changeValue={handleNumber} />
          </div>
        </div>
        <div className="table-content-border"></div>
        <h3 className="mb-5">Добавить наименнование.</h3>
        <div className="row  align-content-center product-edit-item">
          <div className="col-3">
            <Input value={nameValue} placeholder={"Русский"} changeValue={handleName} />
          </div>

          <div className="col-3">
            <Input value={nameSecondValue} placeholder={"English"} changeValue={handleSecondName} />
          </div>

          <div className="col-3">
            <Input value={nameThirdValue} placeholder={"Polski"} changeValue={handleThirdName} />
          </div>
        </div>
        <div className="table-content-border"></div>
        <h3 className="mb-5">Добавить характеристики.</h3>
        <div className="row">
          <div className="col-2">
            <Input type="number" value={weightValue} placeholder={"Введите вес"} changeValue={handleWeight} />
          </div>
          <div className="col-2">
            <Input type="number" value={height} placeholder={"Введите высоту"} changeValue={handleHeight} />
          </div>
          <div className="col-2">
            <Input type="number" value={deep} placeholder={"Введите глубину"} changeValue={handleDeep} />
          </div>
          <div className="col-2">
            <Input type="number" value={width} placeholder={"Введите ширину"} changeValue={handleWidth} />
          </div>

        </div>
        <div className="table-content-border"></div>
        <div className="row">
          <div className="col-3">
            <Select
              label="Выберите тип продукта"
              onClick={(item) => setActiveTypeProduct(item)}
              itemsList={types}
              activeItem={activeTypeProduct} />
          </div>

          <div className="col-3">
            <Select
              label="Выберите категорию продукта"
              onClick={(item) => setActiveTypeCategory(item)}
              itemsList={categoryes}
              activeItem={activeCategory} />
          </div>
        </div>

        <div className="table-content-border"></div>
        { activeTypeProduct.id === 1 ?
        <div className="row">
          <div className="col-3">
            <SelectProduct
              label="Добавить изделие"
              onClick={(item) => addProvision(item)}
              itemsList={provision}
              activeItem={activeProvision} />
          </div>
          <div className="col-9 provision-list">
            <div className="row mb-4" style={{"fontWeight" : "bold"}}>
              <div className="col-3">
                Артикул
              </div>
              <div className="col-3">
                Наименнование
              </div>
              <div className="col-3">
                Кол-во
              </div>
              <div className="col-3">
                Удалить
              </div>
            </div>
            {productProvisionList.map(item => <ListProvision
                deleteItem={deleteItemProvision}
                addItem={(provision) => {
                  setPostProvision([...postProvision.filter(e => e.id !== provision.id), provision]);
                }}
                key={item.id} item={item} />)}
          </div>

        </div> : null}

        <div className="d-flex mt-5">
          <Button text="Добавить" onClick={() => {
            addItem();
          }
          }/>
        </div>

      </form>
    </React.Fragment>
  );
};
export default AddProduct;