import React from 'react';


import Table from './Table';

import './Regions.sass';

const Regions = () => {

  return (
    <div className="regions inner d-flex flex-column">
      <h1>Справочник Регионов</h1>
      <Table />
    </div>
  );
};

export default Regions;