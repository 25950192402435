import React from 'react';

import './TableHeader.sass';

const TableHeader = ({deleteSize, deleteWeight, deleteOpt, deleteMegaopt, deleteRoz, deleteStorage, deleteProducts}) => {
  return(
    <div className="name-titles row">

      <div className="col-2 title">Артикул</div>
      <div className="col-2 title">Наименование</div>

      <div className="col-1 text-center title" onClick={() => deleteSize()}>Объем</div>
      <div className="col-1 text-center title" onClick={() => deleteWeight()}>Вес</div>
      <div className="col-1 text-center title" onClick={() => deleteOpt()}>Опт</div>
      <div className="col-1 text-center title" onClick={() => deleteMegaopt()}>Мегаопт</div>
      <div className="col-1 text-center title" onClick={() => deleteRoz()}>Розница</div>

      <div className="col-1 title text-center" onClick={() => deleteStorage()}>Складская</div>
      <div className="col-1 title text-right" onClick={() => deleteProducts()}>Производство</div>
      <div className="col-1 title justify-content-center d-flex">Удалить</div>

    </div>
  );
};
export default TableHeader;