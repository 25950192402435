import React from 'react';

import Table from './Table';

const Categories = () => {
  return (
    <div className="regions inner d-flex flex-column">
      <h1>Справочник Категорий продуктов</h1>
      <Table />
    </div>
  );
};
export default Categories;