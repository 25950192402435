import React, { useState } from 'react';

import apiLangs from '../../service/apiLangs';
import Input from '../../components/Input';
import Button from '../../components/Button';

const AddLang = ({close, update}) => {

  const [ nameValue, setName ]              = useState('');
  const [ shortNameValue, setShortName ]    = useState('');

  const handleSubmit = e => e.preventDefault();
  const handleName = (value) => setName(value);
  const handleShortName = (value) => setShortName(value);

  const addItem = () => {
    const api = new apiLangs();
    api.addLang(nameValue, shortNameValue).then(res => {
      update(res);
    });
  };

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit} className="form form-add shadow-box">
        <h2>Добавить новый язык в список языков.</h2>
        <div className="row align-content-center">
          <div className="col-2">
            <Input value={nameValue} placeholder={"Введите краткое название"} changeValue={handleShortName} />
          </div>
          <div className="col-3">
            <Input
              value={nameValue}
              placeholder={"Введите название"}
              changeValue={handleName} />
          </div>
          <div className="col-3">
            <Button mode="small" text="Добавить" onClick={() => {addItem();close()}}/>
          </div>
        </div>
      </form>
    </React.Fragment>
  );
};
export default AddLang;