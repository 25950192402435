import React from 'react';

import Table from './Table';
import './Langs.sass';

const Regions = () => {
  return (
    <div className="regions inner d-flex flex-column">
      <h1>Справочник Языков</h1>
      <Table />
    </div>
  );
};

export default Regions;