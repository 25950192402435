import React, { useEffect, useState } from 'react';

import apiRricesList from '../../service/apiPricesList';
import apiRegions from '../../service/apiRegions';
import apiLangs from '../../service/apiLangs';
import apiCurrency from '../../service/apiCurrency';

import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import BackupIcon from '@material-ui/icons/Backup';

import AddPrice from './AddPrice';
import EditPrice from './EditPrice';

import './PriceList.sass';

const TableHeader = () => {
  return (
    <div className="name-titles row">
      <div className="col-2 d-flex justify-content-center title">
        №
      </div>
      <div className="col-2 title">
        Регион
      </div>
      <div className="col-4 title">
        Комментарий
      </div>
      <div className="col-2 title justify-content-center d-flex">
        Скачать
      </div>
      <div className="col-2 col-xl-2 title justify-content-center d-flex">
        Удалить
      </div>
    </div>
  );
};

const Price = ({item, deleteItem, uploadItem, update, regions, langs, currency}) => {
  const [ show, setShow ] = useState(false);

  return (
    <div key={item.id} className="row">
      <div
       onClick={() => setShow(true)}
       className="col-2 d-flex table-item-price justify-content-center">
        {item.id}
      </div>
      <div
        onClick={() => setShow(true)}
        className="col-2 table-item-price">
        {item.region_title}
      </div>
      <div
        onClick={() => setShow(true)}
        className="col-4 table-item-price">
        {item.description}
      </div>
      <div onClick={() => uploadItem(item.id)}
        className="col-2 d-flex justify-content-center">
        <a target="_blank" href={`https://api.plusobject.com/v1/prices/${item.id}/generate-pdf`} className="text-center">
          <BackupIcon />
        </a>
      </div>
      <div onClick={() => deleteItem(item.id)}
        className="col-2 d-flex justify-content-center">
        <div className="text-center">
          <DeleteForeverIcon />
        </div>
      </div>
      <EditPrice
        price={item}
        currency={currency}
        langs={langs}
        regions={regions}
        show={show}
        id={item.id}
        update={update}
        setActiveWindow={() => setShow(false)} />
    </div>
  )
};

const PriceList = () => {

  const [ regions, setRegions ]     = useState([]);
  const [ langs, setLangs ]         = useState([]);
  const [ priceList, setPriceList ] = useState([]);
  const [ currency, setCurrency ]   = useState([]);

  useEffect(() => {
    const api = new apiRricesList();
    api.getPricesList().then(prices => setPriceList(prices));
  }, []);

  useEffect(() => {
    const api = new apiLangs();
    api.getLangs().then(langs => setLangs(langs));
  }, []);

  useEffect(() => {
    const api = new apiRegions();
      api.getRegions().then(regions => {
        setRegions(regions);
      });
  }, []);

  useEffect(() => {
    const api = new apiCurrency();
      api.getCurrency().then(currency => setCurrency(currency));
  }, []);

  const deleteItem = (id) => {
    const api = new apiRricesList();
    api.deletePrice(id).then(prices => setPriceList(prices));
  };
  const uploadItem = (id) => console.log(id);

  return (
    <div className="price-list inner d-flex flex-column">
      <h1>Прайс-листы</h1>
      <AddPrice  update={(res) => {
        console.log(res);
        setPriceList(res)}} />
      <div className="table-content shadow-box mt-4">
        <TableHeader />
        {
          priceList.map(item => {
            return <Price
            currency={currency}
            regions={regions}
            langs={langs}
            key={item.id}
            uploadItem={uploadItem}
            update={(res) => {
              console.log(res);
              setPriceList(res)}}
            deleteItem={deleteItem}
            item={item} />
          })
        }
      </div>
    </div>
  );
};

export default PriceList;