import React, { useEffect, useState, useContext } from 'react';

import { CurrencyContext } from '../../context/currencyContext';

import apiOrders from '../../service/apiOrders';
import apiRegions from '../../service/apiRegions';
import apiLangs from '../../service/apiLangs';
import apiProducts from '../../service/apiProducts';

import Button from '../../components/Button';
import ModalWindow from '../../components/ModalWindow';
import TableHeader from './TableHeader';
import Order from './Order';

import './Orders.sass';
import AddOrder from './AddOrder';

const Orders = () => {

  const currency                      = useContext(CurrencyContext);
  const [ ordersList, setOrdersList ] = useState([]);
  const [ regions, setRegions ]       = useState([]);
  const [ langs, setlangs ]           = useState([]);
  const [ products, setProducts ]           = useState([]);
  const [ activeAdd, setActiveAdd ]   = useState(false);

  useEffect(() => {
    const api = new apiOrders();
    api.getOrderList().then(orders => setOrdersList(orders));
  }, [ currency.currencyId ]);

  useEffect(() => {
    const api = new apiRegions();
    api.getRegions().then(regions => setRegions(regions));
  }, []);

  useEffect(() => {
    const api = new apiLangs();
    api.getLangs().then(langs => setlangs(langs));
  }, []);

  useEffect(() => {
    const api = new apiProducts();
    api.getProductList().then(products => setProducts(products));
  }, []);

  const deleteItem = (id) => {
    const api = new apiOrders();
    api.deleteOrder(id).then(prices => setOrdersList(prices));
  };

  const uploadItem = (id) => console.log(id);

  return (
    <div className="orders inner d-flex flex-column">
      <h1>Заказы</h1>
      <div className="ml-auto">
        <Button text="Добавить" onClick={() => setActiveAdd(true)} />
      </div>
      {
        activeAdd ?
        <ModalWindow close={() => setActiveAdd(false)}>
          <AddOrder
            uploadItem={(list) => setOrdersList(list)}
            closeWindow={() => setActiveAdd(false)}
            currency={currency.currencyList}
            regions={regions}
            langs={langs} />
        </ModalWindow>
        : null
      }
      <div className="table-content shadow-box mt-4">
        <TableHeader />
        {
          ordersList.map((item) => {
            return (
              <Order
                currency={currency}
                regions={regions}
                langs={langs}
                key={item.id}
                uploadItem={uploadItem}
                update={(res) => setOrdersList(res)}
                deleteItem={deleteItem}
                item={item} />
            )
          })
        }
      </div>

    </div>
  );
};

export default Orders;