import React, { useEffect, useState, useContext } from 'react';

import apiProducts from '../../../../service/apiProducts';
import { CurrencyContext } from '../../../../context/currencyContext';

const Price = ({defaultValue = '', productId, regionId, price_type, percent, update}) => {

  const currency = useContext(CurrencyContext);
  const [ value, setValue ]                   = useState(defaultValue);
  const [ activeCerrency, setActiveCurrency ] = useState(currency.selectCurrency);

  useEffect(() => {
    const api = new apiProducts();
    if(value !== defaultValue) api.updatePriceProduct(productId, regionId, price_type, percent, value);
  }, [activeCerrency]);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const onBlur = () => {
    const api = new apiProducts();
    if(value !== defaultValue) api.updatePriceProduct(productId,regionId, price_type, percent, value);
    update();
  };

  return (
    <div className="price-product d-flex">
      <input style={price_type !== 4 ? {"fontWeight": "600", "background" : "#000", "color" : "#fff"} : null } type="number"
      value={value}
      onChange={(e) => setValue(e.target.value)}
      onBlur={() => onBlur()} />
    </div>
  )
};

const Region = ({region, prices, deleteRegion, editRegion, extraPrice, productId, update}) => {

  const pricesList = region.prices.map((price) => {

    const item = prices.find(item => price.id === item.id);
    if(item) {
      switch (item.id) {
        case 0:
        case 2:
          return  <span key={price.name} className="product-regions-exp">
                    {price.price ? price.price : 0}
                  </span>
        case 8:
          return   <span key={price.name} className="product-regions-exp">
                      <Price
                        update={update}
                        defaultValue={price.price}
                        productId={productId}
                        regionId={region.id}
                        price_type={4} />
                    </span>
        case 1:
          return  <span onClick={() => editRegion(region.id)} key={price.name} className="product-regions-exp">
                    {price.price ? price.price : 0}
                  </span>
        default:
          return  <span style={{"background" : "#000"}} key={price.name} className="product-regions-exp d-flex justify-content-between">
                    <Price
                      update={update}
                      defaultValue={price.price}
                      productId={productId}
                      regionId={region.id}
                      price_type={price.id === 3 ? 1 : price.id === 4 ? 2 : 3 } />
                    {extraPrice ?

                    <React.Fragment>
                       <span className={price.extra_percent > 0 ? "extra-price-plus" : "extra-price-minus"}>{price.extra_percent}%</span>
                       <div className={price.extra_percent > 0  ? "extra-price-plus" : "extra-price-minus"}>{price.extra_absolute}</div>
                    </React.Fragment>

                    : null}
                  </span>
      }
    };
    return null;
  });

  return (
    <div className="col-2" key={region.id}>
      <div className="product-regions-item" onClick={() => deleteRegion(region.id)}>{region.title}</div>
      {pricesList}
    </div>
  );
};
export default Region;