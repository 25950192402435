import React, { useEffect, useState } from 'react';

import ApiExpenses from '../../../service/apiResource';

import TableHeader from '../TableHeader';
import ModalWindow from '../../../components/ModalWindow';
import AddExp from '../../../components/AddExp';
import EditExp from '../../../components/EditExp';
import PanelSearch from '../../../components/PanelSearch';

import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import './Table.sass';

const Table = ({ typeExp }) => {
  const [ expensesList, setList ] = useState([]);
  const [ activeAdd, setActiveAdd ] = useState(false);
  const [ activeEdit, setActiveEdit ] = useState(false);
  const [ itemEdit, setItemEdit] = useState('');
  const [ searchList, setSearchList ] = useState([]);

  useEffect(() => {
    const api = new ApiExpenses();
    api.getExpenses(typeExp).then(res => setList(res));
  }, [ typeExp, setList ]);

  useEffect(() => {
    setList(searchList);
  }, [searchList]);

  const searchChange = (list) => setSearchList(list);
  const updateAdd = (list) => setList(list.filter(item => item.type === typeExp));
  const deleteItem = (id) => {
    const api = new ApiExpenses();
    api.deleteExpenses(id).then(res => {
      const arr = res.filter(item => item.type === typeExp);
      setList(arr);
    });
  };

  const closeAdd = () => setActiveAdd(false);
  const open = () => setActiveAdd(true);
  const closeEdit = () => setActiveEdit(false);
  const openEdit = (item) => {
    setItemEdit(item);
    setActiveEdit(true);
  };

  const expListItem = expensesList.map(item => {
    return (
      <div className="expenses row" key={item.id}>
        <div className="col-2 expenses-item text-center">
          {item.number}
        </div>
        <div onClick={() => openEdit(item)} className="col-4 expenses-item ">
          {item.title}
        </div>
        <div onClick={() => openEdit(item)} className="col-4 tooltip expenses-item">
          <div className="description">{item.description}</div>
          {item.description ? <div className="tooltiptext">{item.description}</div> : null}
        </div>
        <div
          onClick={() => deleteItem(item.id)}
          className="col-2 expenses-item text-center d-flex justify-content-center">
          <div className="text-center">
            <DeleteForeverIcon />
          </div>
        </div>
      </div>
    );
  });

  return (
    <div className="table expenses">
      {activeAdd ?
        <ModalWindow close={closeAdd}>
          <AddExp
            expensesList={expensesList}
            type={typeExp}
            update={updateAdd}
            close={closeAdd}/>
        </ModalWindow> : null}

      {activeEdit ?
      <ModalWindow close={closeEdit}>
        <EditExp
          item={itemEdit}
          update={updateAdd}
          close={closeEdit}/>
      </ModalWindow> : null}

      <div className="content-fixed">
        <PanelSearch
          typeId={typeExp}
          type="expenses"
          searchList={searchChange}
          setActiveAdd={open}/>

        <div className="table-content-header shadow-box">
          <TableHeader />
        </div>
      </div>

      <div className="table-content shadow-box">
        {expListItem}
      </div>
    </div>
  );
};

export default Table;
