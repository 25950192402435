import React, { useState } from 'react';
import './CurrencySelect.sass';

const CurrencySelect = ({currency}) => {

  const [ selectActive, setActive ] = useState(false);
  const {currencyList, selectCurrency, setId} = currency;

  return (
    <div className="currency-select">
      { selectCurrency? <div className="currency-select-item" onClick={() => setActive(!selectActive)}>
        <span>{selectCurrency.currency_short}</span>
      </div>: null}
      {
        selectActive ?
        <div className="currency-select-content">
          {currencyList ? currency.currencyList.map(item => {
            return (
              <div
                onClick={() => {
                  setActive(false);
                  setId(item.id);
                  localStorage.currencyId = item.id
                }}
                key={item.id}
                className="currency-select-content-item">
                {item.currency_short}
              </div>
            )}): null}
        </div> : null
      }
    </div>
  );
};

export default CurrencySelect;