import React, { useState } from 'react';

import apiProductType from '../../service/apiProductType';
import Input from '../../components/Input';
import Button from '../../components/Button';

const AddProductType = ({close, update}) => {

  const [ nameValue, setName ] = useState('');

  const handleSubmit = e => e.preventDefault();
  const handleName = (value) => setName(value);

  const addItem = () => {
    const api = new apiProductType();
    api.addProductType(nameValue).then(res => {
      update(res);
    });
  };

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit} className="form form-add shadow-box">
        <h2>Добавить новый язык в список языков.</h2>
        <div className="row align-content-center">
          <div className="col-10">
            <Input
              value={nameValue}
              placeholder={"Введите название"}
              changeValue={handleName} />
          </div>
          <div className="col-2">
            <Button mode="small" text="Добавить" onClick={() => {addItem();close()}}/>
          </div>
        </div>
      </form>
    </React.Fragment>
  );
};
export default AddProductType;