import React from 'react';
import { NavLink } from "react-router-dom";

import './Costs.sass';

const Costs = () => {
  return (
    <div className="costs">
      {/* <h1 className="inner">Справочник Расходов</h1> */}
      <div className="costs-nav">
        <NavLink activeClassName="selected" to="/costs/production">
          Производственные расходы
        </NavLink>
        <NavLink activeClassName="selected" to="/costs/transports">
          Транспортные расходы
        </NavLink>
        <NavLink activeClassName="selected" to="/costs/additional">
          Дополнительные расходы
        </NavLink>
      </div>
    </div>
  );
};

export default Costs;