import React, { useState } from 'react';

import Input from '../Input';
import Button from '../Button';
import ApiExpenses from '../../service/apiResource';


const EditExp = ({close, update, item}) => {

  const [ nameValue, setName ] = useState(item.title);
  const [ numberValue, setNumber] = useState(item.number);
  const [ descriptionValue, setDescription] = useState(item.description);

  const handleSubmit = e => e.preventDefault();
  const handleName = (value) => setName(value);
  const handleNumber = (value) => setNumber(value);
  const handleDescription = (value) => setDescription(value);

  const addItem = () => {
    const api = new ApiExpenses();
    api.editExpenses(numberValue, nameValue, item.id, descriptionValue).then(res => update(res));
  };

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit} className="form form-add shadow-box">
        <h2>Изменить расход в списке расходов.</h2>
        <div className="row align-content-center">
          <div className="col-2">
            <Input type="number"
              defaultValue={item.number}
              value={numberValue}
              placeholder={"Введите номер"}
              changeValue={handleNumber} />
          </div>

          <div className="col-3">
            <Input
              defaultValue={item.title}
              value={nameValue}
              placeholder={"Введите название"}
              changeValue={handleName} />
          </div>

          <div className="col-4">
            <Input
              defaultValue={item.description}
              value={descriptionValue}
              placeholder={"Введите комментарий"}
              changeValue={handleDescription} />
          </div>

          <div className="col-3">
            <Button mode="small" text="Изменить" onClick={() => {addItem();close()}}/>
          </div>
        </div>
      </form>
    </React.Fragment>
  );
};
export default EditExp;