import React, { useState, useEffect } from 'react';

import apiCurrency from '../service/apiCurrency';

const CurrencyContext = React.createContext();

const Context = ({children}) => {

  const defaultId = Number(localStorage.currencyId);
  if ( !localStorage.currencyId ) localStorage.currencyId  = 980;
  const [ currencyList, setList ] = useState([]);
  const [ selectCurrency, setCurrency ] = useState(null);
  const [ currencyId, setId ] = useState( defaultId );

  useEffect(() => {
    const api = new apiCurrency();
    api.getCurrency().then(res => setList(res));
  }, []);

  useEffect(() => {
    setCurrency(currencyList.find((item) => item.id.toString() === localStorage.currencyId));
  }, [ currencyId, currencyList ]);

  return (
    <CurrencyContext.Provider value={
      { currencyId: currencyId,
        selectCurrency: selectCurrency,
        currencyList: currencyList,
        setId: (id) => setId(id)
      }
    }>
      {children}
    </CurrencyContext.Provider>
  );
};

export { Context, CurrencyContext };