import React, { useState, useEffect } from 'react';

import apiCurrency       from '../../service/apiCurrency';
import apiRegions        from '../../service/apiRegions';
import apiLangs          from '../../service/apiLangs';
import apiProducts       from '../../service/apiProducts';
import apiPricesList     from '../../service/apiPricesList';

import Button            from '../../components/Button';
import Select            from '../../components/Select';
import Input             from '../../components/Input';
import ModalWindow       from '../../components/ModalWindow';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

const AddPrice = ({update}) => {
  const [ activeWindow, setActiveWindow ]               = useState(false);
  const [ currency, setCurrency ]                       = useState([]);
  const [ langs, setLangs ]                             = useState([]);
  const [ regions, setRegions ]                         = useState([]);
  const [ products, setProducts ]                       = useState([]);
  const [ descriptionValue, setDescription]             = useState('');
  const [ showRoz, setShowRoz ]                         = useState(true);
  const [ showOpt, setShowOpt ]                         = useState(true);
  const [ showMegaOpt, setShowMegaOpt ]                 = useState(true);
  const [ activeItemCurrency, setActiveItemCurrency ]   = useState({title: 'Выберите валюту'});
  const [ activeItemLang, setActiveItemLang ]           = useState({title: 'Выберите язык'});
  const [ activeItemRegion, setActiveItemRegion ]       = useState({title: 'Выберите регион'});
  const [ allProducts, setAllProducts]                  = useState([]);

  const handleDescription = (value) => setDescription(value);

  useEffect(() => {
    const api = new apiLangs();
    if (activeWindow) {
      api.getLangs().then(langs => setLangs(langs));
    }
  }, [activeWindow]);

  useEffect(() => {
    const api = new apiCurrency();
    if (activeWindow) {
      api.getCurrency().then(currency => setCurrency(currency));
    }
  }, [activeWindow]);

  useEffect(() => {
    const api = new apiRegions();
    if (activeWindow) {
      api.getRegions().then(regions => {
        setRegions(regions);
        setActiveItemRegion(regions[0])
      });
    }
  }, [activeWindow]);

  useEffect(() => {
    const api = new apiProducts();
    const controller = new AbortController();
    const signal = controller.signal;
    if (activeWindow) {
      if (activeItemRegion.id) {
        api.getProductList(activeItemRegion.id, signal, activeItemCurrency.id).then(productList => {
          setProducts(productList);
          setAllProducts(productList);
        });
      };
    };
  }, [  activeItemCurrency, activeItemRegion, activeWindow ]);

  useEffect(() => {
    if (activeWindow) {
      if (activeItemRegion.title !== 'Выберите регион') {
        if (activeItemCurrency.title === 'Выберите валюту' && activeItemLang.title === 'Выберите язык') {
          setActiveItemCurrency(currency.find(item => item.id === activeItemRegion.currency_id));
          setActiveItemLang(langs.find(item => item.id === activeItemRegion.lang_id));
        };
      };
    };
  }, [ activeItemRegion, activeWindow ]);

  const deleteItem = (id) => {
    setProducts(products.filter(item => item.id !== id));
  };

  const addPrice = () => {
    const arrIdProduct = products.map(item => item.id);
    const api = new apiPricesList();
    const arrPriceType = [];

    if (showRoz) arrPriceType.push(3);
    if (showOpt) arrPriceType.push(2);
    if (showMegaOpt) arrPriceType.push(1);
    api.addPrice(activeItemCurrency.id, activeItemRegion.id, activeItemLang.id, arrPriceType, arrIdProduct, descriptionValue).then(list => update(list));
  };

  const updateProductList = () => {
    setProducts(allProducts);
  };

  return (
    <div className="d-flex">
      {
        activeWindow ?
        <ModalWindow  close={() => setActiveWindow(false)}>
          <form onSubmit={e => e.preventDefault()} className="form form-add shadow-box">
            <h2>Новый прайс</h2>

            <div className="row">
              <div className="col-2">
                <Select label='Выберите регион' onClick={(item) => {
                  setActiveItemRegion(item);
                  }} itemsList={regions} activeItem={activeItemRegion} />
              </div>
              <div className="col-2">
                <Select label='Выберите валюту' onClick={(item) => setActiveItemCurrency(item)} itemsList={currency} activeItem={activeItemCurrency} />
              </div>
              <div className="col-2">
                <Select label='Выберите язык' onClick={(item) => setActiveItemLang(item)} itemsList={langs} activeItem={activeItemLang} />
              </div>
              <div className="col-3">
                <Input
                  value={descriptionValue}
                  placeholder={"Введите комментарий"}
                  changeValue={handleDescription} />
              </div>

               <div className="col-3">
                <Button onClick={() => {
                  updateProductList();
                }} text="Добавить все" />
              </div>

            </div>

            <div className="table-content table shadow-box mt-5">
              <div className="name-titles row">
                <div className="col-2 d-flex justify-content-center title">
                  Артикул
                </div>
                <div className="col-2 title">
                  Наименование
                </div>
                <div onClick={() => setShowRoz(!showRoz)} className="col-2 title d-flex justify-content-end">
                  Розница
                </div>
                <div onClick={() => setShowOpt(!showOpt)} className="col-2 title d-flex justify-content-end">
                  Опт
                </div>
                <div  onClick={() => setShowMegaOpt(!showMegaOpt)} className="col-2 title d-flex justify-content-end">
                  Мегаопт
                </div>
                <div className="col-2 col-xl-2 title justify-content-center d-flex">
                  Удалить
                </div>
              </div>

              {
                products.map(item => {
                  return (
                    <div className="regions row" key={item.id}>
                      <div className="col-2 regions-item">
                        {item.sku}
                      </div>
                      <div className="col-2 regions-item">
                        {item.langList[activeItemLang.id - 1] ? item.langList[activeItemLang.id - 1].product_title : null}
                      </div>
                      <div className="col-2 regions-item text-right justify-content-end">
                        { showRoz? item.regionData[0].prices[6].price : null}
                      </div>
                      <div className="col-2 regions-item text-right justify-content-end">
                        { showOpt ? item.regionData[0].prices[6].price : null}
                      </div>
                      <div className="col-2 regions-item text-right justify-content-end">
                        { showMegaOpt ? item.regionData[0].prices[6].price : null}
                      </div>
                      <div
                        onClick={() => deleteItem(item.id)}
                        className="col-2 regions-item text-center d-flex justify-content-center">
                        <div className="text-center">
                          <DeleteForeverIcon />
                        </div>
                      </div>
                    </div>
                  )
                })
              }
            </div>
            <div className="mt-4">
              <Button text="Создать" onClick={() => {addPrice(); setActiveWindow(false)}} />
            </div>
          </form>
        </ModalWindow> : null
      }
      <div className="ml-auto">
        <Button text="+" onClick={() => setActiveWindow(true)} />
      </div>
    </div>
  );
};

export default AddPrice;