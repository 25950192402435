import React from 'react';
import { Link } from "react-router-dom";

import NavSelect from '../NavSelect';

import './Nav.sass';

const Nav = ({list}) => {

  const links = list.map(link => {
    if (link.name === "Справочники") {
      return <NavSelect key="0" name={link.name} links={link.subPage} />
    };
    return <Link key={link.id} to={link.link}><div className="nav-link">{link.name}</div></Link>
  });

  return (
    <div className="nav d-flex">
      {links}
    </div>
  );
};

export default Nav;