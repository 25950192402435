import React, {useState, useEffect, useContext} from 'react';

import ApiProducts from '../../service/apiProducts';
import apiRegions from '../../service/apiRegions';
import apiLangs from '../../service/apiLangs';
import { CurrencyContext } from '../../context/currencyContext';

import AddProduct from '../../components/AddProduct'
import ModalWindow from '../../components/ModalWindow';
import TableHeader from './TableHeader';
import Table from './Table';
import PanelSearch from '../../components/PanelSearch';
import Select from '../../components/Select';

import './Products.sass';

const Products = () => {

  const [ itemList, setList ]             = useState([]);
  const [ activeAdd, setActiveAdd ]       = useState(false);
  const [ searchList, setSearchList ]     = useState([]);
  const [ regions, setRegions ]           = useState([]);
  const [ activeRegion, setActiveRegion ] = useState({title: "Выберите регион", id: 0});
  const [ langs, setlangs ]               = useState([]);
  const [ activeLang, setActiveLang ]     = useState();

  const [ sizeShow, setSizeShow ]         = useState(true);
  const [ weightShow, setWeightShow]      = useState(true);
  const [ optShow, setOptShow]            = useState(true);
  const [ megaoptShow, setMegaoptShow]    = useState(true);
  const [ rozShow, setRozShow]            = useState(true);
  const [ storageShow, setStorageShow ]   = useState(true);
  const [ productsShow, setProductsShow ] = useState(true);
  const currency                          = useContext(CurrencyContext);
  const { currencyId }                    = currency;

  useEffect(() => {
    const api = new apiRegions();
    api.getRegions().then(regions => {
      setRegions(regions);
      setActiveRegion(regions[0])
    });
  }, []);

  useEffect(() => {
    const api = new ApiProducts();
    const controller = new AbortController();
    const signal = controller.signal;
    if(activeRegion) {
      api.getProductList(activeRegion.id, signal).then(res => setList(res));
    };
    return () => {
      controller.abort();
    };
  }, [setList, activeAdd, activeRegion, currencyId]);
  useEffect(() => setList(searchList), [searchList]);

  useEffect(() => {
    const api = new apiLangs();
    api.getLangs().then(lengs => {
      setlangs(lengs);
      setActiveLang(lengs[0])
    });
  }, []);

  const deleteItem = (id) => {
    const api = new ApiProducts();
    api.deleteProduct(id, activeRegion.id).then(res => {
      setList(res);
    });
  };

  const searchChange      = (list) => setSearchList(list);
  const closeAdd          = () => setActiveAdd(false);
  const deleteSize        = () => setSizeShow(!sizeShow);
  const deleteWeight      = () => setWeightShow(!weightShow);
  const deleteOpt         = () => setOptShow(!optShow);
  const deleteMegaopt     = () => setMegaoptShow(!megaoptShow);
  const deleteRoz         = () => setRozShow(!rozShow);
  const deleteStorage     = () => setStorageShow(!storageShow);
  const deleteProducts    = () => setProductsShow(!productsShow);

  return (
      activeRegion ?
      <div className="products inner">

        {activeAdd ?
          <ModalWindow close={closeAdd}>
            <AddProduct
              regionId={activeRegion.id}
              updateList={(list) => setList(list)}
              close={closeAdd}/>
          </ModalWindow> : null}

        <div className="content-fixed">
          <div className="products-title">
            <div className="row align-items-center">

              <div className="col-2">
                <h1>Список Товаров</h1>
              </div>

              <div className="col-2 ml-auto">
                { activeRegion.title !== "Выберите регион" ?
                <Select label="Выберите регион" onClick={(item) => setActiveRegion(item)} itemsList={regions} activeItem={activeRegion} />
                : null}
              </div>

              <div className="col-2 ml-auto">
                { activeRegion.title !== "Выберите регион"  ?
                  <Select label="Выберите регион" onClick={(item) => setActiveLang(item)} itemsList={langs} activeItem={activeLang} />
                : null}
              </div>

              <div className="col-6">
                <PanelSearch
                  idRegion={activeRegion.id}
                  type="products"
                  searchList={searchChange}
                  setActiveAdd={setActiveAdd}/>
              </div>

            </div>
          </div>

          <div className="table-content-header shadow-box">
            <TableHeader
              deleteWeight={deleteWeight}
              deleteOpt={deleteOpt}
              deleteMegaopt={deleteMegaopt}
              deleteRoz={deleteRoz}
              deleteSize={deleteSize}
              deleteStorage={deleteStorage}
              productsShow={productsShow}
              deleteProducts={deleteProducts} />
          </div>
        </div>


        <div className="table-content shadow-box">
          <Table
            activeLang={activeLang}
            sizeShow={sizeShow}
            weightShow={weightShow}
            optShow={optShow}
            megaoptShow={megaoptShow}
            rozShow={rozShow}
            storageShow={storageShow}
            productsShow={productsShow}
            deleteItem={deleteItem}
            itemList={itemList} />
        </div>
      </div>
      : null
  )
};

export default Products;