import React, {useState, useEffect} from 'react';

import apiProducts       from '../../service/apiProducts';
import apiPricesList     from '../../service/apiPricesList';

import Button            from '../../components/Button';
import Select            from '../../components/Select';
import Input             from '../../components/Input';
import ModalWindow       from '../../components/ModalWindow';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

const EditPrice = ({id, price, update, show, setActiveWindow, regions, langs, currency}) => {

  const [ products, setProducts ]                       = useState([]);
  const [ descriptionValue, setDescription]             = useState(price.description);
  const [ showRoz, setShowRoz ]                         = useState(price.options.price_types.indexOf(3) !== -1 ? true : false);
  const [ showOpt, setShowOpt ]                         = useState(price.options.price_types.indexOf(2) !== -1 ? true : false);
  const [ showMegaOpt, setShowMegaOpt ]                 = useState(price.options.price_types.indexOf(1) !== -1 ? true : false);

  const [ allProducts, setAllProducts]                  = useState([]);

  const [ activeItemCurrency, setActiveItemCurrency ]   = useState(currency.find(item => item.id === price.options.currency_id));
  const [ activeItemLang, setActiveItemLang ]           = useState(langs.find(item => item.id === price.options.lang_id));
  const [ activeItemRegion, setActiveItemRegion ]       = useState(regions.find(item => item.title === price.region_title));

  const handleDescription = (value) => setDescription(value);

  useEffect(() => {
    const api = new apiProducts();
    const controller = new AbortController();
    const signal = controller.signal;

    if (show) {
      if (activeItemRegion) {
        api.getProductList(activeItemRegion.id, signal, activeItemCurrency.id).then(productList => {
          setAllProducts(productList);
          setProducts(price.options.products.map(item => {
            return productList.find(e => item === e.id);
          }));
        });
      };
    };
  }, [ activeItemLang, activeItemCurrency, activeItemRegion, show ]);

  const deleteItem = (id) => {
    setProducts(products.filter(item => item.id !== id));
  };
  const editPrice = (id) => {
    const arrIdProduct = products.map(item => item.id);
    const api = new apiPricesList();
    const arrPriceType = [];

    if (showRoz) arrPriceType.push(3);
    if (showOpt) arrPriceType.push(2);
    if (showMegaOpt) arrPriceType.push(1);

    api.editPrice(id,
      activeItemCurrency.id,
      activeItemRegion.id,
      activeItemLang.id,
      arrPriceType,
      arrIdProduct,
      descriptionValue).then(list => update(list));
  };
  const updateProductList = () => {
    setProducts(allProducts);
  };
  return (
    <div className="d-flex">
        {
        show ?
        <ModalWindow close={() => setActiveWindow()}>
          <form onSubmit={e => e.preventDefault()} className="form form-add shadow-box">
            <h2>Изменить прайс</h2>
            <div className="row">
              <div className="col-2">
                {
                  activeItemRegion ? <Select label='Выберите регион' onClick={(item) => setActiveItemRegion(item)} itemsList={regions} activeItem={activeItemRegion} /> : null
                }
              </div>
              <div className="col-2">
                <Select label='Выберите валюту' onClick={(item) => setActiveItemCurrency(item)} itemsList={currency} activeItem={activeItemCurrency} />
              </div>
              <div className="col-2">
                <Select label='Выберите язык' onClick={(item) => setActiveItemLang(item)} itemsList={langs} activeItem={activeItemLang} />
              </div>
              <div className="col-3">
                <Input
                  value={descriptionValue}
                  placeholder={"Введите комментарий"}
                  changeValue={handleDescription} />
              </div>
              <div className="col-3">
                <Button onClick={() => {
                  updateProductList();
                }} text="Добавить все" />
              </div>
            </div>

            <div className="table-content table shadow-box mt-5">

              <div className="name-titles row">
                <div className="col-2 d-flex justify-content-center title">
                  Артикул
                </div>
                <div className="col-2 title">
                  Наименование
                </div>
                <div onClick={() => setShowRoz(!showRoz)} className="col-2 title d-flex justify-content-end">
                  Розница
                </div>
                <div onClick={() => setShowOpt(!showOpt)} className="col-2 title d-flex justify-content-end">
                  Опт
                </div>
                <div  onClick={() => setShowMegaOpt(!showMegaOpt)} className="col-2 title d-flex justify-content-end">
                  Мегаопт
                </div>
                <div className="col-2 col-xl-2 title justify-content-center d-flex">
                  Удалить
                </div>
              </div>

              {products.map(item => {
                  return (
                    <div className="regions row" key={item.id}>
                      <div className="col-2 regions-item">
                        {item.sku}
                      </div>
                      <div className="col-2 regions-item">
                        {item.langList[activeItemLang.id] ? item.langList[activeItemLang.id].product_title : null}
                      </div>
                      <div className="col-2 regions-item text-right justify-content-end">
                        { showRoz? item.regionData[0].prices[6].price : null}
                      </div>
                      <div className="col-2 regions-item text-right justify-content-end">
                        { showOpt ? item.regionData[0].prices[6].price : null}
                      </div>
                      <div className="col-2 regions-item text-right justify-content-end">
                        { showMegaOpt ? item.regionData[0].prices[6].price : null}
                      </div>
                      <div
                        onClick={() => deleteItem(item.id)}
                        className="col-2 regions-item text-center d-flex justify-content-center">
                        <div className="text-center">
                          <DeleteForeverIcon />
                        </div>
                      </div>
                    </div>
                  )
                })}
            </div>
            <div className="mt-4">
              <Button text="Изменить" onClick={() => { editPrice(id); setActiveWindow()}} />
            </div>
          </form>
        </ModalWindow> : null
      }
    </div>
  );
};

export default EditPrice;