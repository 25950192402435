export default class apiRegions {
  _apiBase = `https://api.plusobject.com/v1/`;
  currencyId = `?currency_id=${localStorage.currencyId}`
  async getResourse(url) {
    const res = await fetch(`${this._apiBase}${url}`);

    if(!res.ok) {
      throw new Error(`Could not fetch ${this._apiBase}` +
      `, received ${res.status}`)
    }
    return await res.json();
  };

  async getResourseAdd(url,data) {
    const res = await fetch(`${this._apiBase}${url}${this.currencyId}`, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, cors, *same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
          'Content-Type': 'application/json',
          // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      redirect: 'follow', // manual, *follow, error
      referrer: 'no-referrer', // no-referrer, *client
        body: JSON.stringify(data),
    });
    if(!res.ok) {
      throw new Error(`Could not fetch ${url}` +
      `, received ${res.status}`)
    }
    return await res.json();
  };

  getRegions = () => {
    return this.getResourse('regions');
  };

  searchRegions = (str, controller) => {
    if (str) {
      return this.getResourse(`regions/search/${str}`, controller)
    } else {
      return this.getResourse('regions')
    };
  };

  deleteRegions = (id) => {
    return this.getResourse(`regions/delete/${id}`);
  };

  addRegion = (title, currency_id , description) => {
    return this.getResourseAdd(`regions`, {title, currency_id, description});
  };

  editRegion = (title, id_region, currency_id, description, lang_id) => {
    return this.getResourseAdd(`regions/update/${id_region}`, {title, currency_id, description, lang_id});
  };
};