import React, {useState, Fragment} from 'react';

import ApiProducts from '../../../../service/apiProducts';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import Region from './Region';
import ProductExp from './ProductExp';
import TransportExp from './TransportExp';
import ModalWindow from '../../../../components/ModalWindow';

import './Table.sass';

const Table = ({ productId , regionList, updateProduct, addId, prices, deletePrice, updatePrice, deleteRegion ,updateRegion, product }) => {

  const [ activeExpProd, setActiveExpProd ]            = useState(false);
  const [ activeExpTransport, setActiveExpTransport ]  = useState(false);
  const [ idRegion, setIdRegion ]                      = useState(null);
  const [ region, setRegion ]                          = useState(null);
  const [ extraPrice, setExtraPrice ]                  = useState(false);

  const update = () => {
    const api = new ApiProducts();
    api.getProduct(productId).then(res => {
      if (res) updateProduct();
    });
  };

  const editRegion = (id) => {
    setIdRegion(id);
    setActiveExpTransport(true);
    setRegion(regionList.find(item => item.id === id));
  };

  const pricesList = prices.map(item => {

    const priceRender = () => {
      switch (item.id) {
        case 0:
          return  <div className="product-table-title d-flex align-content-center" key={item.id}>
                    <div onClick={() => {addId(item.id);deletePrice(item.id)}}><ClearRoundedIcon /></div>
                    <span className="product-link" style={{"color": "#000", "fontWeight": '100'}} onClick={() => setActiveExpProd(true)}>{item.price}</span>
                  </div>
        case 2:
          return  <div className="product-table-title d-flex align-content-center" key={item.id}>
                    <div onClick={() => {addId(item.id);deletePrice(item.id)}}><ClearRoundedIcon /></div>
                    <span style={{"color": "#000", "fontWeight": '100'}}>{item.price}</span>
                  </div>
        case 3:
        case 4:
        case 5:
          return <div className="product-table-title d-flex align-content-center" key={item.id}>
                    <div onClick={() => {addId(item.id);deletePrice(item.id)}}><ClearRoundedIcon /></div>
                    <span style={{"color": "#000"}}>{item.price}</span>
                  </div>
        default:
          return  <div className="product-table-title d-flex align-content-center" key={item.id}>
                    <div onClick={() => {addId(item.id);deletePrice(item.id)}}><ClearRoundedIcon /></div>
                    <span style={{"color": "#000", "fontWeight": '100'}}>{item.price}</span>
                  </div>
      };
    };

    return priceRender();
  });

  const productRegions = regionList.map((item) => {
    return (
      <Region
        update={update}
        productId={productId}
        extraPrice={extraPrice}
        editRegion={editRegion}
        deleteRegion={deleteRegion}
        prices={prices}
        key={item.id}
        region={item} />
  )});

  return (
    <Fragment>

      {activeExpProd ?
      <ModalWindow close={()=> { setActiveExpProd(false); update() }}>
        <ProductExp product={product} productId={productId} />
      </ModalWindow> : null}

      {activeExpTransport ?
      <ModalWindow close={()=> { setActiveExpTransport(false); update() }}>
        <TransportExp product={product} region={region} productId={productId} idRegion={idRegion} />
      </ModalWindow> : null}

      <div className="product-table shadow-box">
        <div className="row">
          <div className="col-2">
            <div className="product-table-btn">
              <div className="table-add-price">
                <div
                  className="table-add-price-btn"
                  onClick={() => {updatePrice(); setExtraPrice(false)}}>
                    Добавить все
                </div></div>
            </div>
            <div className="product-table-list">
              {pricesList}
            </div>
          </div>

          <div className="col-9 product-regions">
            <div className="row">
              {productRegions}
            </div>
          </div>

          <div className="col-1">
            <div className="region-add">
              <div onClick={() => updateRegion()} className="region-add-btn">Все</div>
            </div>
          </div>
        </div>
        <div className="extra-price-btn" onClick={() => setExtraPrice(!extraPrice)}>Наценка</div>
      </div>
    </Fragment>
  );
};

export default Table;