import React, { useState } from 'react';

import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';

const SelectProduct = ({ activeItem, itemsList, onClick, iconLeft = false, label}) => {

  const [ show, toggleShow ] = useState(false);

  return (
    <div className="select">
      <label>{label}</label>
      <div className="select-active shadow-box"
        onClick={() => toggleShow(!show)}>
        <div className="select-active-item">
          {iconLeft ? <div style={{marginRight: "6px"}}>
            <QueryBuilderIcon fontSize="small" />
          </div> : null }
          { activeItem.title ? activeItem.title : activeItem.currency}
        </div>
        <ExpandMoreRoundedIcon/>
      </div>
      {
        show ?
        <div className="select-list shadow-box">
          { itemsList.map((item) => <div onClick={() => {
            onClick(item);
            toggleShow(!show);
          }} key={ item.id }>
            <span>{item.sku} </span>
            { item.langList[0].product_title ? item.langList[0].product_title : item.currency }</div>) }
        </div> : null
      }
    </div>
  );
};

export default SelectProduct;