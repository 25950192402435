export default class apiLangs {
  _apiBase = `https://api.plusobject.com/v1/`;
  currencyId = `?currency_id=${localStorage.currencyId}`
  async getResourse(url) {
    const res = await fetch(`${this._apiBase}${url}`);

    if(!res.ok) {
      throw new Error(`Could not fetch ${this._apiBase}` +
      `, received ${res.status}`)
    }
    return await res.json();
  };

  async getResourseAdd(url,data) {
    const res = await fetch(`${this._apiBase}${url}${this.currencyId}`, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, cors, *same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
          'Content-Type': 'application/json',
          // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      redirect: 'follow', // manual, *follow, error
      referrer: 'no-referrer', // no-referrer, *client
        body: JSON.stringify(data),
    });
    if(!res.ok) {
      throw new Error(`Could not fetch ${url}` +
      `, received ${res.status}`)
    }
    return await res.json();
  };

  getLangs = () =>this.getResourse('lang');
  addLang = (title, short) => this.getResourseAdd('langs', { title, short });
  deleteLang = (id) => this.getResourse(`langs/delete/${id}`);
};