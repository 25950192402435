export default class apiPriceList {

  _apiBase = `https://api.plusobject.com/v1/`;
  currencyId = `?currency_id=${localStorage.currencyId}`
  async getResourse(url) {
    const res = await fetch(`${this._apiBase}${url}`);

    if(!res.ok) {
      throw new Error(`Could not fetch ${this._apiBase}` +
      `, received ${res.status}`)
    }
    return await res.json();
  };

  async postResourseAdd(url, data, controller) {
    const res = await fetch(`${this._apiBase}${url}${this.currencyId}`, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, cors, *same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
          'Origin': '*',
          'Content-Type': 'application/json',
          // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      redirect: 'follow', // manual, *follow, error
      referrer: 'no-referrer', // no-referrer, *client
        body: JSON.stringify(data),
    }, controller);
    if(!res.ok) {
      throw new Error(`Could not fetch ${url}` +
      `, received ${res.status}`)
    }
    return await res.json();
  };

  getPrice = (id) => {
    return this.getResourse(`prices/${id}`);
  };

  getPricesList = () => {
    return this.getResourse('prices');
  };

  deletePrice = (id) => {
    return this.getResourse(`prices/delete/${id}`)
  };

  addPrice = (currency_id, region_id, lang_id, price_types, products, description) => {
    return this.postResourseAdd('prices', {
      currency_id,
      region_id,
      lang_id,
      price_types,
      products,
      description
    });
  };


  editPrice = (id,currency_id, region_id, lang_id, price_types, products, description) => {
    return this.postResourseAdd(`prices/update/${id}`, {
      currency_id,
      region_id,
      lang_id,
      price_types,
      products,
      description
    });
  };
};